import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Popover, Alert } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import { isEmpty } from 'lodash';

import CustomInput from '../Adapters/CustomInput';
import theme from '../../utils/theme';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import { openPaymentSuccessModal } from '../../utils/redux/modalSlice';
import { updateCreateBooking } from '../../utils/redux/createBookingSlice';
import UserForm from './UserForm';
import { setBookingSuccess } from '../../utils/redux/bookingSuccessSlice';
import {
  contactNumbersValidation,
  convertHashMapToArray,
  errorLogger,
} from '../../utils/helpers/component';
import apiInstance from '../../utils/apiInstance';
import BookingTimeSlots from './BookingTimeSlots';
import CreateBookingCalander from '../CreateBookingCalander';
import { ERROR_TOAST_OPTIONS } from '../../utils/constants';
import { capitalize } from '../../utils/helpers/string';
import useCreateBookingCalanderClassNamesHook from '../CustomHooks/useCreateBookingCalanderClassNamesHook';
import { AddGarageServices } from './AddGarageServices';

const Container = styled.div``;

const Title = styled.h5`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-3xl']};
  font-weight: 700;
  line-height: ${(props) => props.theme.lineHeights['text-3xl']};
  margin-bottom: 40px;
`;

const Form = styled.form``;

const InputContainer = styled.div`
  flex: 1;
  position: relative;
`;

const Lable = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 25px 0;
  gap: 15px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const ButtonContainer = styled.div`

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
  `;

const bookAppointmentButtonStyle = {
  marginTop: '25px',
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '15px 40px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
};

const Notes = styled.div`
  & > h3 {
    color: ${(props) => props.theme.colors.secondary.dark};
    font-family: ${(props) => props.theme.fontFamilies.lato};
    font-size: ${(props) => props.theme.fontSizes['text-lg']};
    font-weight: 600;
    line-height: ${(props) => props.theme.lineHeights['text-lg']};
    margin-bottom: 10px;
  }
  & > p {
    color: ${(props) => props.theme.colors.secondary.dark};
    font-family: ${(props) => props.theme.fontFamilies.lato};
    font-size: ${(props) => props.theme.fontSizes['text-lg']};
    font-weight: 500;
    line-height: ${(props) => props.theme.lineHeights['text-lg']};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  padding: 10px;
  border: 2px solid rgb(16, 26, 32);
`;

const Appointment = () => {
  const { garage } = useSelector((state) => ({
    garage: state.garage,
  }));
  const [anchorEl, setAnchorEl] = useState(false);
  const [openTimeSlot, setOpenTimeSlot] = useState(false);
  const [bookingsPerSlotMap, setBookingsPerSlotMap] = useState({});

  const [loading, setLoading] = useState({
    slotsByDayLoading: true,
    submitLoading: false,
  });

  const { createBooking, slots, holidays } = useSelector((state) => ({
    createBooking: state.createBooking,
    slots: state.slots,
    holidays: state.holidays,
  }));
  const { data } = useCreateBookingCalanderClassNamesHook();


  const [ isPreferredDateSelected, setIsPreferredDateSelected ] = useState(false);


  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleUpdateTimeSlot = useCallback((value) => {
    dispatch(updateCreateBooking({ slotId: value }));
  }, []);

  const updateFieldData = useCallback((fieldLable, value) => {
    dispatch(updateCreateBooking({ [fieldLable]: value }));
  }, []);

  const handleUpdateDate = useCallback((value) => {
    setIsPreferredDateSelected(true);
    dispatch(updateCreateBooking({ dropoffDate: value }));
  }, []);

  const handleUpdateReview = (e) => {
    updateFieldData('remarks', e.target.value);
  };

  const dateObject = createBooking?.value?.dropoffDate;
  
  const formattedDate = moment(`${dateObject.year}-${dateObject.month}-${dateObject.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  const holiday = convertHashMapToArray(holidays?.value)?.filter((item) => item?.formatted_date === formattedDate);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading((state) => ({ ...state, submitLoading: true }));
      const createBookingData = createBooking?.value;
      const checkContactNumbersValidation = contactNumbersValidation(createBookingData);
      if (checkContactNumbersValidation && holiday.length === 0) {
        const serviceIds = createBookingData.services.map((item) => {
          return item.id
        });
        const body = {
          serviceIds,
          remarks: createBookingData?.remarks,
          dropoffDate: formattedDate,
          slotId: createBookingData?.slotId,
          customer: {
            firstName: createBookingData?.fName,
            lastName: createBookingData?.lName,
            phoneNumber: `+${createBookingData?.phoneNumber}`,
            id: createBookingData?.userId
          },
          vehicle: {
            id: createBookingData?.vehicleId,
            make: createBookingData?.vehicleMake,
            model: createBookingData?.vehicleModel,
            year: createBookingData?.vehicleManufactureYear.toString(),
            licencePlate: createBookingData?.liscencePlate,
          },
          garageId: garage?.value?.id,
        }

        const res = await apiInstance.post('/appointment', body);
        const dropoffTime = {
          ...slots?.value[createBookingData.slotId],
          value: slots?.value[createBookingData.slotId]?.formatted_am_pm,
        };
        dispatch(
          setBookingSuccess({
            ...createBookingData,
            orderId: res?.data?.content?.id,
            dropoffTime,
            vehicleName: capitalize(
              `${createBookingData?.vehicleMake} ${createBookingData?.vehicleModel} ${createBookingData?.vehicleManufactureYear}`
            ),
          })
        );
        dispatch(openPaymentSuccessModal());
      } else if (!checkContactNumbersValidation) {
        addToast(`Invalid Contact Numbers`, ERROR_TOAST_OPTIONS);
      } else {
        addToast(`The selected Day is not a working day`, ERROR_TOAST_OPTIONS);
      }
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading((state) => ({ ...state, submitLoading: false }));
    }
  };

  const handleOpenCalnder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setOpenTimeSlot(false);
  };

  const handleOpenTimeslot = (event) => {
    setOpenTimeSlot(event.currentTarget);
  };

  const formatDate = (date) => {
    const { year, month, day } = date;
    const dateObj = new Date(year, month - 1, day); // months are 0-indexed in JS Date
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

  // const getBookingsPerSlotByDay = async ({ selectedDate, garageId }) => {
  //   try {
  //     const slotsPerDay = await apiInstance.get(
  //       `/slots/getTimeslotsAverage?date=${selectedDate}&garage_id=${garageId}`
  //     );
  //     setBookingsPerSlotMap(
  //       getBookingSlotsMap({
  //         slotsByDayDayData: slotsPerDay.data.content,
  //         slots: convertHashMapToArray(slots.value),
  //       })
  //     );
  //   } catch (error) {
  //     errorLogger(error, addToast, () => {});
  //   } finally {
  //     setLoading((state) => ({ ...state, slotsByDayLoading: false }));
  //   }
  // };

  // useEffect(() => {
  //   const date = moment(
  //     `${createBooking?.value?.dropoffDate?.year}-${createBooking?.value?.dropoffDate?.month}-${createBooking?.value?.dropoffDate?.day}`,
  //     'YYYY-M-D'
  //   );
  //   if (!slots.isLoading) {
  //     getBookingsPerSlotByDay({
  //       selectedDate: date.toISOString(),
  //       garageId: garageDetails.id,
  //     });
  //   }
  // }, [slots.isLoading, slots.value]);

  // const handleOpenAndClose = () => {
  //   dispatch(
  //     updateCreateBooking({
  //       includeDiagnostics: !createBooking.value.includeDiagnostics,
  //       diagnosticsDescription: '',
  //       diagnosticsImages: [],
  //     })
  //   );
  // };

  const findSelectedSlotLabel = () => {
    const selectedSlot = convertHashMapToArray(slots?.value)?.find(
      (slot) => slot?.id === createBooking.value.slotId
    );
    if (!isEmpty(selectedSlot)) {
      const startTime = moment(selectedSlot?.start_time, 'HH:mm').format('h:mm A');;
      const endTime = moment(selectedSlot?.end_time, 'HH:mm').format('h:mm A');
      const formattedAmPm = `${startTime} - ${endTime}`
      return formattedAmPm;
    }
    return '';
  };

  return (
    <Container>
      <Title>Create an Appointment</Title>
      <Form onSubmit={handleSubmit}>
        <AddGarageServices />
        <Notes>
          <h3>Any specific issues or requests? Type below the services!</h3>
          <TextArea
            value={createBooking?.value?.remarks}
            onChange={handleUpdateReview}
            placeholder="Type your remarks"
          />
        </Notes>
        <Flex>
          <InputContainer>
          <Lable>Choose Your Preferred Date</Lable>
            <CustomInput
              style={{
                height: '50px',
                cursor: 'pointer',
              }}
              onClick={handleOpenCalnder}
              value={isPreferredDateSelected ? formatDate(createBooking?.value?.dropoffDate) : null}
              name="Date"
            />
            <CalendarMonthIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: 40,
                cursor: 'pointer',
              }}
              onClick={handleOpenCalnder}
            />
            <Popover
              open={anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <CreateBookingCalander
                calendarClassName="create-booking-custom-calendar"
                onClick={handleUpdateDate}
                date={createBooking?.value?.dropoffDate}
                handleClose={handleClose}
                customDaysClassName={data.customClassNames}
                minimumDate={data.minimumDate}
                maximumDate={data.maximumDate}
              />
            </Popover>
          </InputContainer>
          <InputContainer>
            <Lable>Select Dropoff Time</Lable>
            <CustomInput
              style={{ height: '50px', cursor: 'pointer' }}
              onClick={handleOpenTimeslot}
              value={findSelectedSlotLabel()}
              name="Time slot"
            />
            <AccessTimeIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: 40,
                cursor: 'pointer',
              }}
              onClick={handleOpenTimeslot}
            />
            <Popover
              open={openTimeSlot}
              anchorEl={openTimeSlot}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <BookingTimeSlots
                onClick={handleUpdateTimeSlot}
                handleClose={handleClose}
                bookingsPerSlotMap={bookingsPerSlotMap}
              />
            </Popover>
          </InputContainer>
        </Flex>
        <Flex>
          {createBooking.value.slotId && (
            <Alert severity='info' style={{width: '100%'}}>Please note that this is an all-day appointment</Alert>
          )}
        </Flex>
        <UserForm />
        <ButtonContainer>
          <CustomButton
            loading={loading.submitLoading}
            type="submit"
            variant={variants.primary}
            customStyle={{ ...bookAppointmentButtonStyle }}
            loaderStyle={{ fontSize: '1rem' }}
          >
            Book Appointment
          </CustomButton>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default Appointment;
