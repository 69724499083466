import styled from 'styled-components';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledBodyTableCell,
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import theme from '../../utils/theme';

const Container = styled.div`
  margin-top: 20px;
`;

const Heading = styled.h3`
  margin: 0 0 10px 0;
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-base'],
  fontFamily: theme.fontFamilies.lato,
  fontWeight: '400',
};

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-base'],
  fontWeight: '500',
  border: 'none',
  padding: '10px',
};

const SubTotalText = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const Amount = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const ServiceItems = (props) => {
  const { bookingDetails } = props;

  const totalServicesPrice = bookingDetails?.services?.reduce(
    (accumulater, current) =>
      accumulater + (current?.service_price ? current.service_price : 0) * (current?.quantity ? current?.quantity : 0),
    0
  );

  return (
    <Container>
      <Heading>List of Services</Heading>
      <StyledTableContainer
        sx={{ marginBottom: '10px', border: '1px solid #010101', borderRadius: '10px', padding: '20px' }}
      >
        <StyledTable sx={{ borderCollapse: 'collapse', borderSpacing: '0 0.6rem' }} aria-label="customized table">
          <StyledTableHead>
            <StyledTableHeadRow>
              <StyledHeadTableCell sx={{ ...headTableCellStyle }}>SI No.</StyledHeadTableCell>
              <StyledHeadTableCell sx={{ ...headTableCellStyle }}>Name</StyledHeadTableCell>
              <StyledHeadTableCell sx={{ ...headTableCellStyle }}>Price</StyledHeadTableCell>
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {bookingDetails?.services?.map((service, index) => (
              <StyledTableBodyRow key={service?.service_id}>
                <StyledBodyTableCell sx={{ ...bodyTableCellStyle }}>{index + 1}.</StyledBodyTableCell>
                <StyledBodyTableCell sx={{ ...bodyTableCellStyle }}>{service?.service}</StyledBodyTableCell>
                <StyledBodyTableCell sx={{ ...bodyTableCellStyle }}>{service?.service_price}</StyledBodyTableCell>
              </StyledTableBodyRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={{ mr: 4 }}>
        <SubTotalText>Total Service price :</SubTotalText>
        <Amount>${totalServicesPrice?.toFixed(2)}</Amount>
      </Stack>
    </Container>
  );
};

ServiceItems.propTypes = {
  bookingDetails: PropTypes.object,
};

export default ServiceItems;
