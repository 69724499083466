import { Box, Stack } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import success from '../../assets/images/svg/success.svg';
import theme from '../../utils/theme';

const Container = styled.div`
  margin-top: 30px;
  display: grid;
  place-items: end;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    margin-top: 20px;
  }
`;

const InnerContainer = styled.div`
  width: 450px;
  margin-right: 60px;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    width: 350px;
    margin-right: 30px;
  }
`;

const PromoCodeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const PromoCodeName = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.primary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 500;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const SuccessText = styled.p`
  color: ${(props) => props.theme.colors.miscellaneous.green};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 500;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const PaymentInfo = styled.div`
  padding: 20px 0;
`;

const BillInfo = styled.h4`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
  font-weight: 400;
`;

const Amount = styled.p`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const TotalText = styled.h2`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.primary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
`;

const InvoiceText = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const PaymentSummary = (props) => {
  const { bookingDetails } = props;
  const totalLabourPrice = bookingDetails?.labour_prices
    ? bookingDetails?.labour_prices?.reduce(
        (accumulater, current) =>
          accumulater + (current?.price_per_hour ? current?.price_per_hour : 0) * (current?.hours ? current?.hours : 0),
        0
      )
    : 0;

  const totalPartsPrice = bookingDetails?.parts
    ? bookingDetails?.parts?.reduce(
        (accumulater, current) =>
          accumulater + (current?.unit_price ? current?.unit_price : 0) * (current?.quantity ? current.quantity : 0),
        0
      )
    : 0;

  const totalServicesPrice = bookingDetails?.services.reduce(
    (accumulater, current) =>
      accumulater + (current?.service_price ? current.service_price : 0) * (current?.quantity ? current?.quantity : 0),
    0
  );

  let subTotal = totalServicesPrice + totalPartsPrice + totalLabourPrice;
  if (bookingDetails?.diagnostics_id) {
    subTotal += bookingDetails?.diagnostics_id?.price ?? 0;
  }

  const discountedSubTotal = subTotal - bookingDetails?.promocode_discount_price ?? 0;
  const tax = discountedSubTotal * ((bookingDetails?.tax_percentage || 15) / 100);

  const getPaymentMode = (mode) => {
    switch (mode) {
      case 1:
        return 'Debit Card';
      case 2:
        return 'Cash';
      case 3:
        return 'Credit Card';
      default:
        break;
    }
  };

  return (
    <Container>
      <InnerContainer>
        {bookingDetails?.promocode_id && (
          <PromoCodeInfo>
            <Box>
              <Heading>Promocode</Heading>
              <PromoCodeName>{bookingDetails?.promocode_id?.code}</PromoCodeName>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Image src={success} />
              <Text>Applied</Text>
            </Stack>
          </PromoCodeInfo>
        )}

        <PaymentInfo>
          <Stack direction="row" justifyContent="space-between">
            <BillInfo>Sub Total</BillInfo>
            <Amount>${subTotal.toFixed(2)}</Amount>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <BillInfo>Discount</BillInfo>
            <Amount>-${bookingDetails?.promocode_discount_price.toFixed(2)}</Amount>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <BillInfo>Tax</BillInfo>
            <Amount>${tax.toFixed(2)}</Amount>
          </Stack>
        </PaymentInfo>
        {bookingDetails?.is_payment_completed ? (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '10px' }}>
              <Heading>Payment</Heading>
              <SuccessText>Completed</SuccessText>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Heading>Payment Mode</Heading>
              <Text>{getPaymentMode(bookingDetails?.payment_mode)}</Text>
            </Stack>
          </>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Heading>Payment</Heading>
            <Text>Pending</Text>
          </Stack>
        )}
        <Box
          sx={{
            p: { xs: 3, sm: 0 },
            border: { xs: 1, sm: 0 },
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '20px 0',
          }}
        >
          <TotalText>
            Total: <Span>${bookingDetails?.total_price && bookingDetails?.total_price.toFixed(2)}</Span>
          </TotalText>
          <InvoiceText>
            <a
              href={bookingDetails?.invoice_url}
              target="_blank"
              style={{ color: theme.colors.secondary.darker }}
              rel="noreferrer"
            >
              Invoice
            </a>
          </InvoiceText>
        </Box>
      </InnerContainer>
    </Container>
  );
};

PaymentSummary.propTypes = {
  bookingDetails: PropTypes.object,
};

export default PaymentSummary;
