import { createSlice } from "@reduxjs/toolkit";
import { modalInitialState } from "./states";

const initialState = modalInitialState;

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openBookingSevicesModal: (state) => {
      state.bookingSevicesModal = true;
    },
    closeBookingSevicesModal: (state) => {
      state.bookingSevicesModal = false;
    },
    openPaymentSuccessModal: (state) => {
      state.paymentSuccessModal = true;
    },
    closePaymentSuccessModal: (state) => {
      state.paymentSuccessModal = false;
    },
  },
});

export const {
  openCustomerBookingRemainderModal,
  closeCustomerBookingRemainderModal,
  openCreateAppointmentModal,
  closeCreateAppointmentModal,
  openBookingSevicesModal,
  closeBookingSevicesModal,
  openPaymentSuccessModal,
  closePaymentSuccessModal,
  openAddGarageServiceModal,
  closeAddGarageServiceModal,
  openUserProfileModal,
  closeUserProfileModal,
  openImportServiceModal,
  closeImportServiceModal,
  openEditBookingServicesModal,
  closeEditBookingServicesModal,
  openEditBookingVehicleInfoModal,
  closeEditBookingVehicleInfoModal,
  openEditBookingCustomerInfoModal,
  closeEditBookingCustomerInfoModal,
  openEditBookingCustomerAddressModal,
  closeEditBookingCustomerAddressModal,
  openSubmitBugReportModal,
  closeSubmitBugReportModal,
} = modalSlice.actions;

export default modalSlice.reducer;
