import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { garageInitialState } from './states';

const initialState = garageInitialState;

export const fetchGarageAsync = createAsyncThunk('garage/fetchGarageAsync', async (input) => {
  const { successCallback, errorCallback, value } = input;
  const { garageWebBookingUrl } = value;
  try {
    const garage = await axios.get(`${process.env.REACT_APP_API_END_POINT}/garage/booking/${garageWebBookingUrl}`);
    successCallback();
    return garage.data.content;
  } catch (error) {
    errorCallback(error);
    return initialState.value;
  }
});

const garageSlice = createSlice({
  name: 'garage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGarageAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGarageAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchGarageAsync.rejected, (state) => {
      state.isLoading = true;
    });
  },
});

export default garageSlice.reducer;
