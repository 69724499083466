import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'mxpert-gms.firebaseapp.com',
  projectId: 'mxpert-gms',
  storageBucket: 'mxpert-gms.appspot.com',
  messagingSenderId: '419752388518',
  appId: '1:419752388518:web:f1bae36400e9554c57d167',
  measurementId: 'G-N8SNN8VQNW',
};

const app = initializeApp(firebaseConfig);

export const FIREBASE_DB = getFirestore(app);

export const FIREBASE_STORAGE = getStorage(app);

export const FIREBASE_AUTH = getAuth(app);
