import '../styles/errorPage.scss';

const Page403 = () => (
  <div className="container">
    <div className="wrapper">
      <div className="neon">403</div>
      <div className="message1">You Are Not Authorized.</div>
      <div className="message2">You tried to access a page you did not have prior authorization for.</div>
    </div>
  </div>
);

export default Page403;
