import { getCurrentMomentDateInputObject } from '../helpers/component';

export const helpersInitialState = {
  screenWidth: 0,
};

export const modalInitialState = {
  bookingSevicesModal: false,
  paymentSuccessModal: false,
};

export const garageServicesInitialState = {
  isLoading: true,
  value: [],
};

export const userInitialState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  userDetails: null,
};

export const holidaysInitialState = {
  isLoading: true,
  value: {},
};

export const createBookingInitialState = {
  isLoading: false,
  value: {
    phoneNumber: '',
    fName: '',
    lName: '',
    email: '',
    vehicleName: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleDistanceDriven: '',
    vehicleVIN: '',
    liscencePlate: '',
    vehicleManufactureYear: '',
    services: [],
    dropoffDate: getCurrentMomentDateInputObject(),
    slotId: '',
    remarks: '',
    selectedVehicleId: '',
    userId: '',
  },
};

export const bookingSuccessInitialState = {
  isLoading: true,
  value: {},
};

export const typesInitialState = {
  isLoading: true,
  value: {},
};

export const categoriesInitialState = {
  isLoading: true,
  value: {},
};

export const combinedInitialState = {
  helpers: helpersInitialState,
  modals: modalInitialState,
  garageServices: garageServicesInitialState,
  createBooking: createBookingInitialState,
  bookingSuccess: bookingSuccessInitialState,
  types: typesInitialState,
};

export const slotsInitialState = {
  isLoading: true,
  totalNumberOfSlots: 0,
  totalNumberVehicles: 0,
  value: {},
};

export const garageInitialState = { isLoading: true, value: {} };

export const stateToBePersisted = ['user'];
