import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const CustomLottie = (props) => {
  const { animationData, lottieHeight, lottieWidth } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} height={lottieHeight} width={lottieWidth} />;
};

CustomLottie.propTypes = {
  animationData: PropTypes.node,
  lottieHeight: PropTypes.number,
  lottieWidth: PropTypes.number,
};

CustomLottie.defaultProps = {
  lottieHeight: 400,
  lottieWidth: 400,
};

export default CustomLottie;
