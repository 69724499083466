import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import BookingInfoCard from './BookingInfoCard';
import variants from '../../utils/variants';
import StautsTrackingContent from './StautsTrackingContent';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  padding: 35px 20px 0 20px;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 35px 40px 30px 40px;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  z-index: 100;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    padding: 25px 15px;
  }
`;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-3xl']};
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    line-height: ${(props) => props.theme.lineHeights['text-xl']};
  }
`;

const Top = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-auto-rows: 1fr;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    grid-template-columns: repeat(1, 100%);
    place-content: center;
    grid-gap: 20px;
  }
`;

const Bottom = styled.div`
  margin-top: 40px;
`;

const SubHeading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-lg']};
    line-height: ${(props) => props.theme.lineHeights['text-lg']};
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const ParagraphInfo = styled.p`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const CancelBox = styled.div`
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`;

const CanceledText = styled.h2`
  color: ${(props) => props.theme.colors.miscellaneous.red};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-2xl']};
  }
`;

const BookingStatusTracking = (props) => {
  const { bookingDetails } = props;

  const date = moment(bookingDetails?.dropoff_date);
  const appointmentDate = ` ${date.format(`D MMM YYYY`)}`;

  return (
    <Container>
      <Wrapper>
        <Heading>
          Booking Ref:
          {bookingDetails?.ref_no}
        </Heading>
      </Wrapper>
      <Top>
        <BookingInfoCard varaint={variants.vehicle} bookingDetails={bookingDetails} />
        <BookingInfoCard varaint={variants.customer} bookingDetails={bookingDetails} />
        <BookingInfoCard varaint={variants.address} bookingDetails={bookingDetails} />
      </Top>
      <Box sx={{ mt: 3 }}>
        <SubHeading>Date & Time</SubHeading>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Paragraph>Dropoff Date:</Paragraph>
          <ParagraphInfo>{appointmentDate}</ParagraphInfo>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Paragraph>Time Slot:</Paragraph>
          <ParagraphInfo>{bookingDetails?.dropoff_time?.formatted_am_pm}</ParagraphInfo>
        </Stack>
      </Box>
      <Bottom>
        {bookingDetails?.status === 6 ? (
          <CancelBox>
            <CanceledText>Your Booking was cancelled</CanceledText>
            <ParagraphInfo>Contact this for enquiry: {bookingDetails?.garage_id?.contact_number}</ParagraphInfo>
          </CancelBox>
        ) : (
          <StautsTrackingContent bookingDetails={bookingDetails} />
        )}
      </Bottom>
    </Container>
  );
};

BookingStatusTracking.propTypes = {
  bookingDetails: PropTypes.object,
};

export default BookingStatusTracking;
