import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import theme from '../../utils/theme';


const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  padding: 7px;
  border-radius: 5px;
  border: ${(props) => `1px solid ${props.borderColor}`};
  cursor: pointer;
  pointer-events: ${(props) => (props.filled ? 'none' : 'auto')};
  background-color: ${(props) => (props?.backgroundColor ? props?.backgroundColor : props.theme.colors.secondary.main)};
`;

const Label = styled.p`
  color: ${(props) => props.color};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
`;

const TimeSlot = (props) => {
  const { slot, onClick, bookingsPerSlotMap, handleClose } = props;
  const createBooking = useSelector((state) => state.createBooking);
  const selectedTimeSlot = createBooking?.value?.slotId === slot?.id;
  const startTime = moment(slot?.start_time, 'HH:mm').format('h:mm A');;
  const endTime = moment(slot?.end_time, 'HH:mm').format('h:mm A');
  const formattedAmPm = `${startTime} - ${endTime}`
  if (selectedTimeSlot) {
    return (
      <Container borderColor={theme.colors.primary.main} backgroundColor={theme.colors.primary.main}>
        <Label color={theme.colors.miscellaneous.white}>{formattedAmPm}</Label>
      </Container>
    );
  }
  return (
    <Container
      onClick={() => {
        onClick(slot?.id);
        handleClose();
      }}
      borderColor={bookingsPerSlotMap[slot?.id]?.filled ? '#F0635A' : '#29D697'}
      filled={bookingsPerSlotMap[slot?.id]?.filled}
    >
      <Label color={bookingsPerSlotMap[slot?.id]?.filled ? '#F0635A' : '#29D697'}>{formattedAmPm}</Label>
    </Container>
  );
};

export default TimeSlot;

TimeSlot.propTypes = {
  slot: PropTypes.object,
  onClick: PropTypes.func,
  bookingsPerSlotMap: PropTypes.object,
  handleClose: PropTypes.func,
};
