import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import variants from '../../utils/variants';
import carImage from '../../assets/images/svg/car.svg';
import profileImge from '../../assets/images/svg/profile-filled.svg';
import DecoratedMilesLabel from './DecoratedMilesLabel';

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.dark}`};
  border-radius: 10px;
  position: relative;
  padding: 30px;
  height: 200px;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    justify-content: space-around;
    padding: 10px;
    height: 160px;
    gap: 5px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const Img = styled.img`
  width: 50px;
  margin-bottom: 10px;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    width: 50px;
    height: 50px;
    margin-bottom: 0;
    border-radius: 50%;
  }
`;

const Mid = styled.div``;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  height: 25%;
  margin-bottom: 25px;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    margin-bottom: 10px;
  }
`;

const SubHeading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  margin-bottom: 15px;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-lg']};
    line-height: ${(props) => props.theme.lineHeights['text-lg']};
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const MilesContainer = styled.p`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const BookingInfoCard = (props) => {
  const { varaint, bookingDetails } = props;
  const getUserInfoCard = (varaint) => {
    switch (varaint) {
      case variants.vehicle:
        return (
          <Container>
            <Heading>Vehicle Information</Heading>
            <Wrapper>
              <Left>
                <Img style={{ marginBottom: '15px' }} src={carImage} alt="Car" />
                {bookingDetails?.vehicle_id?.kms_driven && (
                  <>
                    <MilesContainer>
                      <DecoratedMilesLabel miles={bookingDetails?.vehicle_id?.kms_driven} />
                    </MilesContainer>
                    Kms
                  </>
                )}
              </Left>
              <Mid>
                <SubHeading>{bookingDetails?.vehicle_id?.name}</SubHeading>
                <Paragraph>VIN: {bookingDetails?.vehicle_id?.vin}</Paragraph>
                <Paragraph>Vehicle Number: {bookingDetails?.vehicle_id?.licence_plate}</Paragraph>
              </Mid>
            </Wrapper>
          </Container>
        );
      case variants.customer:
        return (
          <Container>
            <Heading>Customer Information</Heading>
            <Wrapper>
              <Left>
                <Img src={profileImge} alt="Car" />
              </Left>
              <Mid>
                <SubHeading>
                  {bookingDetails?.user_id?.first_name} {bookingDetails?.user_id?.last_name}
                </SubHeading>
                <Paragraph>Mobile No: {bookingDetails?.user_id?.mobile_number}</Paragraph>
                <Paragraph>Email: {bookingDetails?.user_id?.email}</Paragraph>
              </Mid>
            </Wrapper>
          </Container>
        );
      case variants.address:
        return (
          <Container>
            <Heading>Garage Information</Heading>
            <Wrapper>
              <Left>
                <Img src={bookingDetails?.garage_id?.logo} alt="Car" />
              </Left>
              <Mid>
                <SubHeading>{bookingDetails?.garage_id?.name}</SubHeading>
                <Paragraph>Mobile No: {bookingDetails?.garage_id?.contact_number}</Paragraph>
                <Paragraph>Email: {bookingDetails?.garage_id?.email}</Paragraph>
              </Mid>
            </Wrapper>
          </Container>
        );
      default:
        break;
    }
  };
  return <Container>{getUserInfoCard(varaint)}</Container>;
};

BookingInfoCard.propTypes = {
  varaint: PropTypes.string,
  bookingDetails: PropTypes.object,
};

export default BookingInfoCard;
