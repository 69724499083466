import { createSlice } from '@reduxjs/toolkit';
import { bookingSuccessInitialState } from './states';

const initialState = bookingSuccessInitialState;

const bookingSuccessSlice = createSlice({
  name: 'bookingSuccess',
  initialState,
  reducers: {
    setBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    },
    resetBookingSuccess: (state) => {
      state.isLoading = true;
      state.value = {};
    },
  },
});

export const { setBookingSuccess, resetBookingSuccess } = bookingSuccessSlice.actions;

export default bookingSuccessSlice.reducer;
