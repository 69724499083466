import PageRouter from './PageRouter';

function App() {
  return (
    <>
      {/* <h1>Hello</h1> */}
      <PageRouter />
    </>
  );
}

export default App;
