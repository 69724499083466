import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import { convertArrayToHashMap } from '../helpers/component';
import { holidaysInitialState } from './states';


const initialState = holidaysInitialState;

export const fetchHolidaysSliceAsync = createAsyncThunk('holidays/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback, value } = input;
  const { rejectWithValue } = thunkApi;
  const { config } = value;
  try {
    const holidays = await axios.get(
      `${process.env.REACT_APP_API_END_POINT}/holiday/${value.garageId}`,
      config
    );
    successCallback();
    const holiday = holidays.data.content.map(item => ({
      ...item,
      formatted_date: moment(item.date).format('YYYY-MM-DD')
    }))
    return convertArrayToHashMap(holiday, 'formatted_date');
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHolidaysSliceAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHolidaysSliceAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchHolidaysSliceAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default holidaysSlice.reducer;
