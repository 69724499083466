import { createSlice, current } from '@reduxjs/toolkit';
import { createBookingInitialState } from './states';

const initialState = createBookingInitialState;

const createBookingSlice = createSlice({
  name: 'createBooking',
  initialState,
  reducers: {
    updateCreateBooking: (state, action) => {
      const { value } = current(state);
      state.value = { ...value, ...action.payload };
    },
    resetCreateBooking: (state) => {
      state.isLoading = false;
      state.value = { ...createBookingInitialState.value };
    },
    updateAddress: (state, action) => {
      const { value } = current(state);
      const address = { ...value.address, ...action.payload };
      state.value.address = { ...address };
    },
    addService: (state, action) => {
      const { value } = current(state);
      const { services } = { ...value };
      state.value.services = [...services, action.payload];
    },
    removeService: (state, action) => {
      const { value } = current(state);
      const { services } = { ...value };
      const tempServices = services.filter((service) => service.serviceId !== action.payload);
      state.value.services = [...tempServices];
    },
    increaseServiceQuantity: (state, action) => {
      const { value } = current(state);
      const { services } = { ...value };
      const findIndex = services.findIndex((service) => service.serviceId === action.payload);
      if (findIndex !== -1) {
        state.value.services[findIndex].quantity += 1;
      }
    },
    decreaseServiceQuantity: (state, action) => {
      const { value } = current(state);
      const { services } = { ...value };
      const findIndex = services.findIndex((service) => service.serviceId === action.payload);
      if (findIndex !== -1) {
        if (services[findIndex].quantity === 1) {
          const tempServices = services.filter((service) => service.serviceId !== action.payload);
          state.value.services = [...tempServices];
        } else {
          state.value.services[findIndex].quantity -= 1;
        }
      }
    },
    updateServiceField: (state, action) => {
      const { value } = current(state);
      const { id, fieldName, fieldValue } = action.payload;
      const { services } = { ...value };
      const updatedServices = services.map((service) => {
        if (service.serviceId === id) {
          return { ...service, [fieldName]: fieldValue };
        }
        return { ...service };
      });
      state.value.services = updatedServices;
    },
    updateDiagnosticImages: (state, action) => {
      const imageUrl = action.payload;
      state.value.diagnosticsImages.push(imageUrl);
    },
    removeDiagnosticImages: (state, action) => {
      const index = action.payload;
      state.value.diagnosticsImages.splice(index, 1);
    },
  },
});

export const {
  updateCreateBooking,
  resetCreateBooking,
  updateAddress,
  addService,
  removeService,
  increaseServiceQuantity,
  decreaseServiceQuantity,
  updateServiceField,
  updateDiagnosticImages,
  removeDiagnosticImages,
} = createBookingSlice.actions;

export default createBookingSlice.reducer;
