import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div``;

const MilesLetter = styled.span`
  padding: 5px;
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.miscellaneous.white};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  margin: 0 3px;
  border-radius: 2px;
`;

const DecoratedMilesLabel = (props) => {
  const { miles } = props;
  const milesArray = String(miles).split('');

  return (
    <Container>
      {milesArray.map((letter, index) => (
        <MilesLetter key={index}>{letter}</MilesLetter>
      ))}
    </Container>
  );
};

DecoratedMilesLabel.propTypes = {
  miles: PropTypes.string,
};

export default DecoratedMilesLabel;
