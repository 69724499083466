import React from 'react';
import styled from 'styled-components';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

import carServiceImage from '../../assets/images/svg/car-service.svg';
import theme from '../../utils/theme';
import CustomButton from '../Adapters/CustomButton';

import LoadingComponent from '../Adapters/LoadingComponent';

const Container = styled.div``;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
`;

const ModalHeading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
`;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  text-align: center;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
  }
`;

const CarImage = styled.img`
  width: 80px;
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
`;

const ButtonContainer = styled.div`
  width: fit-content;
  margin: 60px auto 20px;
`;

const LoadingWindow = styled.div`
  height: 40vh;
  display: grid;
  place-items: center;
`;

const buttonStyle = {
  backgroundColor: 'transparent',
  border: `1px solid ${theme.colors.primary.main}`,
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 40px',
  borderRadius: '8px',
  color: theme.colors.primary.main,
  fontWeight: '600',
  cursor: 'pointer',
};

const ConfirmationMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  `; 


const CreateAppointmentSuccessModalContent = () => {
  const { bookingSuccess, garage } = useSelector((state) => state);

  const date = moment(
    `${bookingSuccess?.value?.dropoffDate.year}/${bookingSuccess?.value?.dropoffDate.month}/${bookingSuccess?.value?.dropoffDate.day}`,
    'YYYY-MM-DD'
  );

  const dateFormatedString = date.format('DD MMM YYYY');

  const handleClose = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <Container>
      {bookingSuccess.isLoading ? (
        <LoadingWindow>
          <LoadingComponent customStyle={{ fontSize: '3rem', color: theme.colors.primary.main }} />
        </LoadingWindow>
      ) : (
        <Container>
          <HeadingContainer>
            <CarImage src={carServiceImage} alt="Car Service Image" />
            <ModalHeading>Booking Confirmed</ModalHeading>
          </HeadingContainer>
          <ConfirmationMessageWrapper>
            <Paragraph>Thank you for booking with {garage.value.name} on:</Paragraph>
            <Paragraph>{dateFormatedString}</Paragraph>
            {/* <Paragraph>{bookingSuccess?.value?.dropoffTime?.value}</Paragraph> */}
          </ConfirmationMessageWrapper>
          <Alert severity='info'>Please note this is an all day appointment, we ask that you drop off your vehicle before {bookingSuccess?.value?.dropoffTime?.end_time}</Alert>
          <ButtonContainer>
            <CustomButton customStyle={{ ...buttonStyle }} onClick={handleClose}>
              CLOSE
            </CustomButton>
          </ButtonContainer>
        </Container>
      )}
    </Container>
  );
};

export default CreateAppointmentSuccessModalContent;
