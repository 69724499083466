import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Container = styled.div``;

const LogoOnlyLayout = () => (
  <Container>
    <Outlet />
  </Container>
);

export default LogoOnlyLayout;
