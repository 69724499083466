import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker';
import moment from 'moment';
import { updateCreateBooking } from '../../utils/redux/createBookingSlice';

const CreateBookingCalander = (props) => {
  const dispatch = useDispatch();
  const {
    maximumDate,
    minimumDate,
    customDaysClassName,
    calendarClassName,
    onClick,
    date,
    handleClose,
  } = props;

  const [selectedDay, setSelectedDay] = useState({
    ...date,
  });

  const handleUpdateTimeSlot = useCallback((value) => {
    dispatch(updateCreateBooking({ slotId: value }));
  }, []);

  const handleChange = (date) => {
    setSelectedDay(date);
    onClick(date);
    handleUpdateTimeSlot('');
    handleClose();
  };
  console.log(minimumDate)
  return (
    <Calendar
      value={selectedDay}
      calendarClassName={calendarClassName}
      onChange={handleChange}
      customDaysClassName={customDaysClassName}
      minimumDate={minimumDate}
      maximumDate={maximumDate}
    />
  );
};

CreateBookingCalander.propTypes = {
  calendarClassName: PropTypes.string,
  onClick: PropTypes.func,
  date: PropTypes.object,
  customDaysClassName: PropTypes.array,
  minimumDate: PropTypes.object,
  maximumDate: PropTypes.object,
  getBookingsPerSlotByDay: PropTypes.func,
  handleClose: PropTypes.func,
};

CreateBookingCalander.defaultProps = {
  calendarClassName: '',
  onClick: () => {},
  customDaysClassName: [],
};

export default CreateBookingCalander;
