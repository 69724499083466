import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../utils/theme';

const LoadingComponent = (props) => {
  const { customStyle } = props;
  return (
    <i
      className="fas fa-spinner fa-spin"
      style={{ color: theme.colors.primary.main, fontSize: '2rem', ...customStyle }}
    />
  );
};

LoadingComponent.propTypes = {
  customStyle: PropTypes.object,
};

export default LoadingComponent;
