import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { convertArrayToHashMap } from "../helpers/component";
import { slotsInitialState } from "./states";

const initialState = slotsInitialState;

export const fetchSlotsAsync = createAsyncThunk(
  "slots/fetchAsync",
  async (input) => {
    const { successCallback, errorCallback, value } = input;

    try {
      const { config } = value;
      const slots = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/slot/garage/${value.garageId}`,
        config
      );
      successCallback();
      const totalNumberOfVehicles = Array.isArray(slots.data.content)
        ? slots.data.content.reduce(
            (accumulater, slot) => accumulater + slot.no_of_vehicles,
            0
          )
        : 0;
      const payload = {
        totalNumberOfSlots: Array.isArray(slots.data.content)
          ? slots.data.content?.length
          : 0,
        totalNumberVehicles: totalNumberOfVehicles,
        value: convertArrayToHashMap(slots.data.content, "id"),
      };
      return payload;
    } catch (error) {
      errorCallback(error);
      return {
        totalNumberOfSlots: 0,
        totalNumberVehicles: 0,
        value: initialState.value,
      };
    }
  }
);

const slotsSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSlotsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSlotsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalNumberOfSlots = action.payload.totalNumberOfSlots;
      state.totalNumberVehicles = action.payload.totalNumberVehicles;
      state.value = action.payload.value;
    });
    builder.addCase(fetchSlotsAsync.rejected, (state) => {
      state.isLoading = true;
      state.value = {};
    });
  },
});

export default slotsSlice.reducer;
