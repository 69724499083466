import '../styles/errorPage.scss';

const Page500 = () => (
  <div className="container">
    <div className="wrapper">
      <div className="neon">500</div>
      <div className="message1">Uh oh, our server failed.</div>
      <div className="message2">
        Sorry about that, we’re working on fixing the problem. Please try refreshing the page or try again later.
      </div>
    </div>
  </div>
);

export default Page500;
