import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import TimeSlot from './TimeSlot';
import { convertHashMapToArray } from '../../utils/helpers/component';

const Container = styled.div`
  min-width: 385px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    min-width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.secondary.main};
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: start;
  align-items: center;
  height: 385px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    height: auto;
  }
`;

const BookingTimeSlots = (props) => {
  const { onClick, handleClose, bookingsPerSlotMap } = props;
  const slots  = useSelector((state) => state.slots.value);
  return (
    <Container>
      <Wrapper>
        {convertHashMapToArray(slots).map((slot) => (
          <TimeSlot
            key={slot.id}
            slot={slot}
            onClick={onClick}
            handleClose={handleClose}
            bookingsPerSlotMap={bookingsPerSlotMap}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

export default BookingTimeSlots;

BookingTimeSlots.propTypes = {
  onClick: PropTypes.func,
  bookingsPerSlotMap: PropTypes.object,
  handleClose: PropTypes.func,
};
