import { createSlice } from "@reduxjs/toolkit";
import { helpersInitialState } from "./states";

const initialState = helpersInitialState;

const helperSlice = createSlice({
  name: "helpers",
  initialState,
  reducers: {
    updateScreenWidth: (state) => {
      state.screenWidth = window.innerWidth;
    },
  },
});

export const { updateScreenWidth } = helperSlice.actions;

export default helperSlice.reducer;
