import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export const RouterLink = styled(Link)`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  text-decoration: none;
  font-weight: 400;
`;

export const StyledTableContainer = styled(TableContainer)``;

export const StyledTable = styled(Table)``;

export const StyledTableHead = styled(TableHead)``;

export const StyledTableBody = styled(TableBody)``;

export const StyledTableHeadRow = styled(TableRow)``;

export const StyledHeadTableCell = styled(TableCell)``;

export const StyledTableBodyRow = styled(TableRow)``;

export const StyledBodyTableCell = styled(TableCell)``;
