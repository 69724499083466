import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import BookingStatusTracking from '../components/BookingStatusTracking';
import Page from '../components/Page';
import apiInstance from '../utils/apiInstance';
import { errorLogger } from '../utils/helpers/component';
import EmptyMessage from '../components/EmptyMessage';

const BookingStatus = () => {
  const params = useParams();
  const { addToast } = useToasts();
  const [bookingDetails, setBookingDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getBookingInfo = async () => {
    try {
      setIsLoading(true);
      const res = await apiInstance.get(`bookings/getBooking/${params.bookingId}`);
      setBookingDetails(res.data.content);
      setIsLoading(false);
    } catch (error) {
      errorLogger(error, addToast, () => {});
    }
  };

  useEffect(() => {
    getBookingInfo();
  }, []);

  return (
    <Page title="Booking Status" loading={isLoading}>
      <Helmet>
        <title>{bookingDetails?.garage_id?.name}</title>
        <link rel="icon" type="image/x-icon" id="faviconTag" href={bookingDetails?.garage_id?.logo} />
        <meta
          name="description"
          content="Need your car serviced? Our experienced team provides top-notch
              automotive repair services at an affordable price. Visit our
              garage today!"
        />
      </Helmet>
      {bookingDetails ? (
        <BookingStatusTracking bookingDetails={bookingDetails} />
      ) : (
        <EmptyMessage
          messageHeading="No Booking Information"
          containerStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
      )}
    </Page>
  );
};

export default BookingStatus;
