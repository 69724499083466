import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { garageServicesInitialState } from './states';

const initialState = garageServicesInitialState;

export const fetchGarageServicesAsyncThunk = createAsyncThunk('garageServices/fetchAsync', async (input) => {
  const { successCallback, errorCallback, value } = input;
  try {
    const { garageId } = value;
    const services = await axios.get(`${process.env.REACT_APP_API_END_POINT}/service/garage/${garageId}`);
    const res = services.data.content;
    const servicesOnWebBooking = res.filter((service) => service.available_on_web_booking === true)
    successCallback();
    return servicesOnWebBooking;
  } catch (error) {
    errorCallback(error);
    return initialState.value;
  }
});

const garageServicesSlice = createSlice({
  name: 'garageServices',
  initialState,
  reducers: {
    setGarageServices: (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGarageServicesAsyncThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGarageServicesAsyncThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchGarageServicesAsyncThunk.rejected, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setGarageServices } = garageServicesSlice.actions;

export default garageServicesSlice.reducer;
