import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";
import { useState } from "react";

import { updateCreateBooking } from "../../utils/redux/createBookingSlice";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 0;
`;

const ChipWrapper = styled.div`
  margin: 4px;
`;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
  margin-bottom: 10px;
    `;

export const AddGarageServices = () => {

  const services  = useSelector(state => state.garageServices.value);
  const map = {};
  services?.forEach((e, index) => {
    map[e.name] = { active: false };
  });

  const [ servicesState, setServicesState ] = useState(map);
  const dispatch = useDispatch();
  const existingRemarks = useSelector(state => state.createBooking.value.remarks);
  const existingServices = useSelector(state => state.createBooking.value.services);

  const handleClickService = (service) => {
    const serviceText = service.name;
    setServicesState(state =>{
      const update = {...state, [serviceText]:{ active: true}};
      return update;
    });
    const finalRemarks = existingRemarks ? `${serviceText}\n${existingRemarks}` : serviceText;
    const updatedServices = [...existingServices, service];
    dispatch(updateCreateBooking({ 'remarks': finalRemarks, 'services': updatedServices }));  
};

const handleDeleteService = (service) => {
  const serviceText = service?.name;
  setServicesState(state =>{
    const update = {...state, [serviceText]:{ active: false}};
    return update;
  });
  const remarksArray = existingRemarks.split('\n').filter(name => name !== serviceText);
  const newRemarks = remarksArray.join('\n');
  const updatedServices = [...existingServices].filter(e => e.name !== service?.name);
  dispatch(updateCreateBooking({ 'remarks': newRemarks, 'services': updatedServices }));
};

  return (
    <>
      <Heading>Select Service(s) (Choose all that apply)</Heading>
      <Wrapper>
        {services.length !== 0 &&
          services.map(service => (
            <ChipWrapper>
              <Chip 
                label={service.name} 
                color='success'
                onClick={servicesState[service?.name]?.active ? null : ()=>handleClickService(service)} 
                onDelete={servicesState[service?.name]?.active ? ()=>handleDeleteService(service) : null} 
                style={{width: 'fit-content'}} 
                variant={servicesState[service?.name]?.active ? 'filled' : 'outlined'}
              />
            </ChipWrapper>
          ))
        }
      </Wrapper>
    </>
  )
};