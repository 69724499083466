import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';

const Container = styled.div`
  margin-bottom: 10px;
  border-radius: 10px;
`;

const Heading = styled.h3`
  margin: 0 0 10px 0;
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const DiagnosticsDetails = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #010101;
  border-radius: 10px;
  margin-bottom: 10px;
  & > h3 {
    margin: 0 0 25px 0;
    color: ${(props) => props.theme.colors.secondary.darker};
    font-family: ${(props) => props.theme.fontFamilies.lato};
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    font-weight: 400;
    line-height: ${(props) => props.theme.lineHeights['text-base']};
  }
  & p {
    color: ${(props) => props.theme.colors.secondary.light};
    font-family: ${(props) => props.theme.fontFamilies.lato};
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    font-weight: 400;
    line-height: ${(props) => props.theme.lineHeights['text-sm']};
    margin-bottom: 10px;
  }
  & img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const SubTotalText = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const Amount = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const DiagnosticsContent = (props) => {
  const { bookingDetails } = props;
  return (
    bookingDetails?.diagnostics_id && (
      <Container>
        <Heading>Diagnostics Cost</Heading>
        <DiagnosticsDetails>
          <h3>{bookingDetails?.diagnostics_id?.description}</h3>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              gap: { xs: '15px', sm: '15px', md: '25px' },
            }}
          >
            {bookingDetails?.diagnostics_id?.images?.map((image, index) => (
              <Box key={index}>
                <img src={image} alt="diagnosticImage" />
              </Box>
            ))}
          </Box>
        </DiagnosticsDetails>
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={{ mr: 4 }}>
          <SubTotalText>Total Diagnostic price :</SubTotalText>
          <Amount>${bookingDetails?.diagnostics_id?.price?.toFixed(2)}</Amount>
        </Stack>
      </Container>
    )
  );
};

DiagnosticsContent.propTypes = {
  bookingDetails: PropTypes.object,
};

export default DiagnosticsContent;
