import { configureStore } from '@reduxjs/toolkit';

import helperReducer from './utils/redux/helpersSlice';
import modalReducer from './utils/redux/modalSlice';
import garageServicesReducer from './utils/redux/garageServicesSlice';
import createBookingReducer from './utils/redux/createBookingSlice';
import bookingSuccessReducer from './utils/redux/bookingSuccessSlice';
import typesReducer from './utils/redux/typesSlice';
import categoriesReducer from './utils/redux/categoriesSlice';
import slotsReducer from './utils/redux/slotSlice';
import garageReducer from './utils/redux/garageSlice';
import { loadState } from './utils/helpers/redux';
import holidaysSlice from './utils/redux/holidaysSlice';

const store = configureStore({
  reducer: {
    helpers: helperReducer,
    modals: modalReducer,
    garageServices: garageServicesReducer,
    createBooking: createBookingReducer,
    bookingSuccess: bookingSuccessReducer,
    serviceTypes: typesReducer,
    serviceCategories: categoriesReducer,
    slots: slotsReducer,
    garage: garageReducer,
    holidays: holidaysSlice,
  },
  preloadedState: loadState(),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
