import { BOOKINGS_COMPLETED, BOOKINGS_CHECKED_IN, BOOKINGS_IN_SERVICE, BOOKINGS_PICKUP } from './localization';

export const BOOKING_SLOTS = [
  {
    id: 0,
    label: '8:00am - 8:30am',
    value: '8:00am - 8:30am',
    filled: false,
    order: 0,
  },
  {
    id: 1,
    label: '8:30am - 9:00am',
    value: '8:30am - 9:30am',
    filled: false,
    order: 1,
  },
  {
    id: 2,
    label: '9:00am - 9:30am',
    value: '9:00am - 9:30am',
    filled: false,
    order: 2,
  },
  {
    id: 3,
    label: '9:30am - 10:00am',
    value: '9:30am - 10:00am',
    filled: false,
    order: 3,
  },
  {
    id: 4,
    label: '10:00am - 10:30am',
    value: '10:00am - 10:30am',
    filled: false,
    order: 4,
  },
  {
    id: 5,
    label: '10:30am - 11:00am',
    value: '10:30am - 11:00am',
    filled: false,
    order: 5,
  },
  {
    id: 6,
    label: '11:30am - 12:00pm',
    value: '11:30am - 12:00pm',
    filled: false,
    order: 6,
  },
];

export const SERVICES_TYPES = [
  { label: 'Regular', tabId: 'simple-tab-0' },
  { label: 'Seasonal', tabId: 'simple-tab-1' },
  { label: 'Custom', tabId: 'simple-tab-2' },
];

export const PAYMENT_METHODS = [
  { id: 1, label: 'Debit/Credit Card', value: 'online' },
  { id: 2, label: 'Cash', value: 'cash' },
];

export const ERROR_TOAST_OPTIONS = { appearance: 'error', autoDismiss: true };

export const SUCCESS_TOAST_OPTIONS = { appearance: 'success', autoDismiss: true };

export const WARNING_TOAST_OPTIONS = { appearance: 'warning', autoDismiss: true };

export const bookingStatusMap = {
  [BOOKINGS_CHECKED_IN]: 2,
  [BOOKINGS_IN_SERVICE]: 3,
  [BOOKINGS_PICKUP]: 4,
  [BOOKINGS_COMPLETED]: 5,
};

export const STREET_NAME_KEYS = ['street_number', 'route', 'sublocality_level_1', 'sublocality_level_2'];

export const BOOKING_DAYS_COLOR_CLASS_NAME_MAP = {
  bookingLessThan50: 'bookingLessThan50',
  booking50To100: 'booking50To100',
  bookingAbove100: 'bookingAbove100',
  bookingAbove100AndDisabled: 'bookingAbove100AndDisabled',
};
