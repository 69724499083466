import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Stack } from '@mui/material';
import {
  StyledBodyTableCell,
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import theme from '../../utils/theme';
import EmptyMessage from '../EmptyMessage';

const Container = styled.div`
  margin-top: 30px;
`;

const Heading = styled.h3`
  margin: 0 0 10px 0;
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-base'],
  fontFamily: theme.fontFamilies.lato,
  fontWeight: '400',
};

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-base'],
  fontWeight: '500',
  border: 'none',
  padding: '10px',
};

const SubTotalText = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const Amount = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const LabourCostContent = (props) => {
  const { bookingDetails } = props;

  const totalLabourPrice = bookingDetails?.labour_prices
    ? bookingDetails?.labour_prices?.reduce(
        (accumulater, current) =>
          accumulater + (current?.price_per_hour ? current?.price_per_hour : 0) * (current?.hours ? current?.hours : 0),
        0
      )
    : 0;

  return (
    <Container>
      <Heading>Labour Charges</Heading>
      <StyledTableContainer
        sx={{ marginBottom: '10px', border: '1px solid #010101', borderRadius: '10px', padding: '20px' }}
      >
        {isEmpty(bookingDetails?.labour_prices) ? (
          <Box>
            <EmptyMessage
              containerStyle={{ height: '25vh' }}
              messageHeadingStyle={{ fontSize: theme.fontSizes['text-base'] }}
              messageHeading="No Labour Charge Added"
              lottieHeight={150}
              lottieWidth={150}
            />
          </Box>
        ) : (
          <StyledTable sx={{ borderCollapse: 'collapse', borderSpacing: '0 0.6rem' }} aria-label="customized table">
            <StyledTableHead>
              <StyledTableHeadRow>
                <StyledHeadTableCell sx={{ ...headTableCellStyle }}>SI No.</StyledHeadTableCell>
                <StyledHeadTableCell sx={{ ...headTableCellStyle }}>Name</StyledHeadTableCell>
                <StyledHeadTableCell sx={{ ...headTableCellStyle }}>Price</StyledHeadTableCell>
              </StyledTableHeadRow>
            </StyledTableHead>
            <StyledTableBody>
              {bookingDetails?.labour_prices?.map((labour, index) => (
                <StyledTableBodyRow key={labour?.labour_price_id}>
                  <StyledBodyTableCell sx={{ ...bodyTableCellStyle }}>{index + 1}.</StyledBodyTableCell>
                  <StyledBodyTableCell sx={{ ...bodyTableCellStyle }}>{labour?.title}</StyledBodyTableCell>
                  <StyledBodyTableCell sx={{ ...bodyTableCellStyle }}>
                    {Number(labour?.price_per_hour) * Number(labour?.hours)}
                  </StyledBodyTableCell>
                </StyledTableBodyRow>
              ))}
            </StyledTableBody>
          </StyledTable>
        )}
      </StyledTableContainer>
      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={{ mr: 4 }}>
        <SubTotalText>Total Labour price :</SubTotalText>
        <Amount>${totalLabourPrice?.toFixed(2)}</Amount>
      </Stack>
    </Container>
  );
};

LabourCostContent.propTypes = {
  bookingDetails: PropTypes.object,
};

export default LabourCostContent;
