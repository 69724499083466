import { useEffect, useState } from 'react';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';

import apiInstance from '../../utils/apiInstance';
import { classNamesForCreateAppointmentCalander, errorLogger } from '../../utils/helpers/component';

const useCreateBookingCalanderClassNamesHook = () => {
  const { garage, slots, holidays } = useSelector((state) => ({
    garage: state.garage,
    slots: state.slots,
    holidays: state.holidays,
  }));
  const businessHours = garage?.value?.business_hours;
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const weekClosedDays = [];
  days.forEach((item, index) => {
    if (businessHours && item in businessHours && businessHours[item].isClosed === true) {
      weekClosedDays.push(index)
    }
  })
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ minimumDate: {}, maximumDate: {}, customClassNames: [] });

  const { addToast } = useToasts();

  useEffect(() => {
    const getCalanderData = async () => {
      const startDate = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const endDate = moment(new Date()).add(3, 'months');
      const minimumDate = {
        year: startDate.get('year'),
        month: startDate.get('month') + 1,
        day: startDate.get('date'),
      };
      const maximumDate = {
        year: endDate.get('year'),
        month: endDate.get('month') + 1,
        day: endDate.get('date'),
      };
      try {
        // const calander = await apiInstance.get(
        //   `slots/getSlotsAverage?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}&garage_id=${
        //     garage?.value?._id
        //   }`
        // );
        const customClassNames = await classNamesForCreateAppointmentCalander({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          // bookingAverageData: calander.data.content,
          totalNumberVehicles: slots.totalNumberVehicles,
          weekClosedDays: weekClosedDays || [],
          holidays: Object.keys(holidays.value),
        });
        setData((state) => ({ ...state, minimumDate, maximumDate, customClassNames }));
      } catch (error) {
        errorLogger(error, addToast, () => {});
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if (!slots.loading && !holidays.isLoading) {
      getCalanderData();
    }
  }, [slots.value, holidays.value]);

  return { loading, error, data };
};

export default useCreateBookingCalanderClassNamesHook;
