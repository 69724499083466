import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 35px 20px 0 145px;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  z-index: 95;
  @media screen and (max-width: 1200px) {
    padding: 35px 20px 0 120px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  font-weight: 400;
`;

const Left = styled.div``;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const CurrentDate = styled.h4`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  font-weight: 500;
`;

const NavbarHeader = (props) => {
  const { header } = props;
  const dateTime = moment(new Date());
  const currentDate = dateTime.format('DD MMM YYYY');

  return (
    <Container>
      <Wrapper>
        <Left>
          <Heading>{header}</Heading>
        </Left>
        <Right>
          <CurrentDate>{currentDate}</CurrentDate>
        </Right>
      </Wrapper>
    </Container>
  );
};

NavbarHeader.propTypes = {
  header: PropTypes.string,
};

NavbarHeader.defaultProps = {
  header: '',
};

export default NavbarHeader;
