import React from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingComponent from '../LoadingComponent';

const Container = styled.div`
  background-color: ${(props) => props.theme.backgroundColors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  overflow-y: auto;
  max-height: 95vh;
  max-width: 85%;
  border-radius: 10px;
  &::-webkit-scrollbar {
    width: 0.35rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f4f4f6;
  }
  & {
    scrollbar-face-color: rgb(107, 107, 107);
    scrollbar-track-color: grey;
  }
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    width: 80%;
  }
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    width: 95%;
    max-height: 85vh;
  }
`;

const LoadingWindow = styled.div``;

const LoadingComponentContainer = styled.div`
  display: grid;
  place-items: center;
  padding: 100px 0;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeading = styled.h5`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  margin-right: 50px;
`;

const CloseIcon = styled.span`
  padding: 6px 10px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.main}`};
  border-radius: 5px;
  cursor: pointer;
`;

const ModalBody = styled.h5`
  margin-top: 25px;
`;

const loaderStyle = {
  fontSize: '3rem',
};

const CustomModal = (props) => {
  const { children, open, handleClose, containerStyle, heading, loading } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <Container style={{ ...containerStyle }}>
        {loading ? (
          <LoadingWindow>
            <HeadingContainer>
              <ModalHeading>{heading}</ModalHeading>
              <CloseIcon onClick={handleClose}>
                <i className="fa-sharp fa-solid fa-xmark" />
              </CloseIcon>
            </HeadingContainer>
            <LoadingComponentContainer>
              <LoadingComponent customStyle={loaderStyle} />
            </LoadingComponentContainer>
          </LoadingWindow>
        ) : (
          <>
            <HeadingContainer>
              <ModalHeading>{heading}</ModalHeading>
              <CloseIcon onClick={handleClose}>
                <i className="fa-sharp fa-solid fa-xmark" />
              </CloseIcon>
            </HeadingContainer>
            <ModalBody>{children}</ModalBody>
          </>
        )}
      </Container>
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  containerStyle: PropTypes.object,
  heading: PropTypes.string,
  loading: PropTypes.bool,
};

CustomModal.defaultProps = {
  open: false,
  handleClose: () => {},
  containerStyle: {},
  heading: '',
  loading: false,
};

export default CustomModal;
