import styled from 'styled-components';

import PropTypes from 'prop-types';
import CustomButton from '../Adapters/CustomButton';
import emptyJson from '../../assets/json/empty1.json';
import CustomLottie from '../Adapters/CustomLottie';

const Container = styled.div`
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

const Title = styled.h3`
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  color: ${(props) => props.theme.colors.secondary.main};
  text-align: center;
  font-weight: 500;
`;

const Paragraph = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  color: ${(props) => props.theme.colors.secondary.main};
  text-align: center;
`;

const EmptyMessage = (props) => {
  const {
    lottiJson,
    messageHeading,
    messageSubInfo,
    handleRedirect,
    buttonText,
    buttonTitle,
    buttonVariant,
    containerStyle,
    lottieHeight,
    lottieWidth,
  } = props;

  return (
    <Container style={containerStyle}>
      <Wrapper>
        <CustomLottie animationData={lottiJson} lottieHeight={lottieHeight} lottieWidth={lottieWidth} />
        {messageHeading && <Title>{messageHeading}</Title>}
        {messageSubInfo && <Paragraph>{messageSubInfo}</Paragraph>}
        {buttonText && (
          <CustomButton onClick={handleRedirect} variant={buttonVariant} title={buttonText}>
            {buttonTitle}
          </CustomButton>
        )}
      </Wrapper>
    </Container>
  );
};

EmptyMessage.propTypes = {
  lottiJson: PropTypes.node,
  messageHeading: PropTypes.string,
  messageSubInfo: PropTypes.string,
  handleRedirect: PropTypes.func,
  buttonText: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonVariant: PropTypes.string,
  containerStyle: PropTypes.object,
  lottieHeight: PropTypes.number,
  lottieWidth: PropTypes.number,
};

EmptyMessage.defaultProps = {
  lottiJson: emptyJson,
  messageHeading: '',
  messageSubInfo: '',
  handleRedirect: () => {},
  buttonText: '',
  buttonTitle: '',
  buttonVariant: '',
  containerStyle: {},
  lottieHeight: 200,
  lottieWidth: 200,
};

export default EmptyMessage;
