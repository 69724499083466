import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Avatar } from '@mui/material';
import moment from 'moment';
import Appointment from '../components/Appointment';
import { fetchTypesAsync } from '../utils/redux/typesSlice';
import { fetchCategoriesAsync } from '../utils/redux/categoriesSlice';
import { axiosErrorCallback, errorLogger } from '../utils/helpers/component';
import { fetchGarageServicesAsyncThunk } from '../utils/redux/garageServicesSlice';
import Page from '../components/Page';
import coverimg from '../assets/images/png/coverimg.png';
import { fetchSlotsAsync } from '../utils/redux/slotSlice';
import { fetchGarageAsync } from '../utils/redux/garageSlice';
import { fetchHolidaysSliceAsync } from '../utils/redux/holidaysSlice';

const BookingSceen = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (max-width: ${(props) => props.theme.screenSizes.medium}) {
    flex-direction: column;
    width: 100%;
    margin: auto;
    overflow-y: scroll;
  }
`;

const LeftContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    width: 100%;
    height: 60vh;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  margin: 0 auto;
  padding: 50px 70px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    padding: 20px 10px;
    width: 90%;
    margin: auto;
  }
  @media (min-width: ${(props) => props.theme.screenSizes.medium}) {
    overflow-y: scroll;
  }
`;

const GarageName = styled.h1`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  margin-top: 20px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    font-size: ${(props) => props.theme.fontSizes['text-2xl']};
    line-height: ${(props) => props.theme.lineHeights['text-2xl']};
  }
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-lg']};
    line-height: ${(props) => props.theme.lineHeights['text-lg']};
  }
`;

const Span = styled.h2`
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-3xl']};
  font-weight: 300;
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    line-height: ${(props) => props.theme.lineHeights['text-xl']};
  }
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-sm']};
  }
`;

const InnerContainer = styled.div`
  position: absolute;
  width: 90%;
  height: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GarageLocation = styled.p`
  color: #e4e4e4;
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 300;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  margin-top: 25px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    line-height: ${(props) => props.theme.lineHeights['text-xl']};
    margin-top: 10px;
  }
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-sm']};
    margin-top: 10px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.p`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-weight: 300;
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  margin-bottom: 8px;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-sm']};
    margin-bottom: 0px;
  }
`;

const OpenDaysText = styled.p`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-weight: 300;
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  margin-bottom: 8px;
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-sm']};
    margin-bottom: 0px;
  }
`;

const Wrapper = styled.div`
  color: #fff;
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes['text-2xl']};
  line-height: ${(props) => props.theme.lineHeights['text-2xl']};
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    line-height: ${(props) => props.theme.lineHeights['text-xl']};
    margin-top: 10px;
  }
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-sm']};
    margin-top: 10px;
  }
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
`;

const CreateAppointment = () => {
  const { garage } = useSelector((state) => ({
    garage: state.garage,
    slots: state.slots,
  }));

  const garageDetails = useMemo(() => garage.value, [garage.value]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  let openDays = [];
  const { garageWebBookingUrl } = useParams();
  if (garageDetails?.business_hours) {
    openDays = Object.entries(garageDetails?.business_hours)
    .filter(([day, { isClosed }]) => !isClosed)
    .sort(([dayA], [dayB]) => {
      if (dayA === "monday") return -1;
      if (dayB === "monday") return 1;
      if (dayA === "friday") return 1;
      if (dayB === "friday") return -1;
      return 0;
    })
    .map(([day, { open, close }]) => {
      const openTime = moment(open, 'HH:mm').format('h:mm A')
      const closeTime = moment(close, 'HH:mm').format('h:mm A')
      return (`${day.charAt(0).toUpperCase() + day.slice(1)} - ${openTime} to ${closeTime}`)
    });
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          fetchGarageAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              axiosErrorCallback({ navigate, error, addToast, errorLoggerCallback: () => {} });
            },
            value: { garageWebBookingUrl },
          })
        )
        await dispatch(
          fetchGarageServicesAsyncThunk({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { config: {}, garageId: response.payload.id },
          })
        );

        await dispatch(
          fetchSlotsAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { garageId: response.payload.id },
          })
        );

        await dispatch(
          fetchHolidaysSliceAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { garageId: response.payload.id },
          })
        );
      } catch (error) {
        axiosErrorCallback({ navigate, error, addToast, errorLoggerCallback: () => {} });
      }
    };
    fetchData();
  }, []);

  return (
    <Page
      title={garageDetails?.name}
      loading={garage.isLoading}
    >
      <BookingSceen>
        <Helmet>
          <title>{garageDetails?.name}</title>
          <link rel="icon" type="image/x-icon" id="faviconTag" href={garageDetails?.logo} />
          <meta
            name="description"
            content="Need your car serviced? Our experienced team provides top-notch
              automotive repair services at an affordable price. Visit our
              garage today!"
          />
        </Helmet>
        <LeftContainer>
          <CoverImage src={coverimg} />
          <InnerContainer>
            <TopContainer>
              <Avatar
                src={garageDetails?.logo}
                sx={{
                  width: { xs: '35px', sm: '100px' },
                  height: { xs: '35px', sm: '100px' },
                  alignSelf: { xs: 'flex-end', sm: 'flex-start' },
                }}
              />
              <GarageName>
                <Span>Welcome to</Span> {garageDetails?.name}
              </GarageName>
              <GarageLocation>
                Location : &nbsp;
                {garageDetails?.address}
              </GarageLocation>
            </TopContainer>
            <BottomContainer>
              <Wrapper>
                <Text>Call</Text>
                {garageDetails?.contact_number}
              </Wrapper>
            </BottomContainer>
          </InnerContainer>
        </LeftContainer>
        <RightContainer>
          <Appointment />
        </RightContainer>
      </BookingSceen>
    </Page>
  );
};

export default CreateAppointment;
