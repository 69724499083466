import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CreateAppointment from './pages/CreateAppointment';
import RootLayout from './layouts/RootLayout';
import Page403 from './pages/Page403';
import Page500 from './pages/Page500';
import Page404 from './pages/Page404';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import BookingStatus from './pages/BookingStatus';

const PageRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route path="/:garageWebBookingUrl" element={<CreateAppointment />} />
      </Route>
      <Route path="/bookingstatus/:bookingId" element={<BookingStatus />} />
      <Route path="/" element={<LogoOnlyLayout />}>
        <Route path="403" element={<Page403 />} />
        <Route path="500" element={<Page500 />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default PageRouter;
