import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TickCircle } from 'iconsax-react';

import theme from '../../utils/theme';
import { updateCreateBooking } from '../../utils/redux/createBookingSlice';
import CustomButton from '../Adapters/CustomButton';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 15px;
  @media (max-width: ${(props) => props.theme.screenSizes.large}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  @media (max-width: ${(props) => props.theme.screenSizes.mobile}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;

const Vehicle = styled.div`
  min-height: 120px;
  width: 100%;
  border: ${(props) =>
    props.active
      ? `1.5px solid ${props.theme.colors.primary.main}`
      : `1.5px solid ${props.theme.colors.secondary.main}`};
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  position: relative;
  & p {
    font-family: ${(props) => props.theme.fontFamilies.lato};
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-xl']};
    font-weight: 400;
  }
  & span {
    font-family: ${(props) => props.theme.fontFamilies.lato};
    font-size: ${(props) => props.theme.fontSizes['text-sm']};
    line-height: ${(props) => props.theme.lineHeights['text-xl']};
    font-weight: 400;
  }
`;

const initialVehicleDetails = {
  vehicleId: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleVIN: '',
  liscencePlate: '',
  vehicleManufactureYear: '',
  vehicleDistanceDriven: '',
};

const Vehicles = (props) => {
  const { vehicles, setIsOpen } = props;
  const { createBooking } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleUnselectAddress = () => {
    dispatch(updateCreateBooking(initialVehicleDetails));
  };

  const handleSelectVehicle = (id) => {
    const vehicle = vehicles.find((vehicle) => vehicle?.id === id);
    if (id === createBooking?.value?.vehicleId) {
      return handleUnselectAddress();
    }
    return dispatch(
      updateCreateBooking({
        vehicleId: id,
        vehicleMake: vehicle?.make,
        vehicleModel: vehicle?.model,
        vehicleVIN: vehicle?.vin,
        liscencePlate: vehicle?.licence_plate,
        vehicleManufactureYear: Number(vehicle?.year),
        vehicleDistanceDriven: Number(vehicle?.kms_driven),
      })
    );
  };

  return (
    <Container>
      {vehicles.map((vehicle, index) => (
        <Vehicle
          key={index}
          active={vehicle?.id === createBooking?.value?.vehicleId}
          onClick={() => {
            handleSelectVehicle(vehicle?.id);
          }}
        >
          <Box sx={{ flex: 1 }}>
            <p>
              <span>{vehicle?.make} {vehicle?.model} {vehicle?.year}</span>
            </p>
            {vehicle?.vin && (
              <p>
                VIN: <span>{vehicle?.vin}</span>
              </p>
            )}
            {vehicle?.licence_plate && (
              <p>
                License Plate: <span>{vehicle?.licence_plate}</span>
              </p>
            )}
          </Box>
          {vehicle?.id === createBooking?.value?.vehicleId && (
            <TickCircle
              style={{ position: 'absolute', right: '5px', top: '5px' }}
              size="20"
              color={theme.colors.primary.main}
            />
          )}
        </Vehicle>
      ))}
    </Container>
  );
};

Vehicles.propTypes = {
  vehicles: PropTypes.array,
};

export default Vehicles;
