const PRIMARY = {
  lighter: '#FCACA1',
  light: '#FB8673',
  main: '#FB5012',
  dark: '#C23D0D',
  darker: '#702308',
};
const SECONDARY = {
  lighter: '#C6C6C7',
  light: '#737476',
  main: '#101A20',
  dark: '#0E171D',
  darker: '#0A1014',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const MiSCELLANEOUS = {
  purple: '#8C3EF1',
  yellow: '#FDC400',
  green: '#29D697',
  white: '#FFFFFF',
  venetianRed: '#F0635A',
  red: '#FF0000',
  grey: '#BFBFBF',
  greyLightest: '#919395',
  greyLight: '#565b66',
  greyRegular: '#393e44',
  greyDark: '#2f343a',
  greyDarker: '#24272c',
  greyDarkest: '#1b1e23',
};

const BACKGROUND_COLORS = {
  blueCyan1: '#E0ECF1',
  blueCyan2: '#D0E1E9',
  blueCyan3: '#F2F9FC',
  white: '#FFFFFF',
  lotion: '#FAFAFA',
  chineseBlack: '#0E171C',
};

const FONT_SIZES = {
  'text-5xl': '3rem',
  'text-4xl': '2.25rem',
  'text-3xl': '1.875rem',
  'text-2xl': '1.5rem',
  'text-xl': '1.25rem',
  'text-lg': '1.125rem',
  'text-base': '1rem',
  'text-sm': '0.875rem',
  'text-xs': '0.75rem',
};

const FONT_WEIGHTS = {
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
  1000: '1000',
};

const LINE_HEIGHTS = {
  'text-5xl': '3rem',
  'text-4xl': '2.5rem',
  'text-3xl': '2.25rem',
  'text-2xl': '2rem',
  'text-xl': '1.75rem',
  'text-lg': '1.75rem',
  'text-base': '1.5rem',
  'text-sm': '1.25rem',
  'text-xs': '1rem',
};

const FONT_FAMILIES = {
  gotham: 'Figtree, sans-serif',
  lato: 'Lato, sans-serif',
};

const SCREEN_SIZES = {
  mobile: '600px',
  tablet: '900px',
  medium: '1200px',
  large: '1536px',
  extraLarge: '1920px',
};

const theme = {
  colors: {
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    miscellaneous: { ...MiSCELLANEOUS },
  },
  fontSizes: {
    ...FONT_SIZES,
  },
  fontWeights: {
    ...FONT_WEIGHTS,
  },
  lineHeights: {
    ...LINE_HEIGHTS,
  },
  fontFamilies: {
    ...FONT_FAMILIES,
  },
  screenSizes: {
    ...SCREEN_SIZES,
  },
  backgroundColors: {
    ...BACKGROUND_COLORS,
  },
};

export default theme;
