import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { useCallback } from 'react';
import LoadingComponent from '../Adapters/LoadingComponent';
import theme from '../../utils/theme';
import NavbarHeader from '../Navbar/NavbarHeader';

const Container = styled.div``;

const Children = styled.div`
  max-width: 1920px;
`;

const LoadingWindow = styled.div`
  height: calc(100vh - 100px);
  display: grid;
  place-items: center;
`;

const Page = (props) => {
  const { children, title, loading, logo, header } = props;

  const getNavbarHeader = useCallback((header = '') => {
    <NavbarHeader header={header} />;
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={logo} type="image/x-icon" />
      </Helmet>
      <Container>
        {getNavbarHeader(header)}
        {loading ? (
          <LoadingWindow>
            <LoadingComponent
              customStyle={{
                fontSize: '3rem',
                color: theme.colors.primary.main,
              }}
            />
          </LoadingWindow>
        ) : (
          <Children>{children}</Children>
        )}
      </Container>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.string,
  logo: PropTypes.node,
};

Page.defaultProps = {
  loading: false,
  header: '',
  title: '',
};

export default Page;
