import styled from 'styled-components';
import PropTypes from 'prop-types';

import variants from '../../../utils/variants';
import theme from '../../../utils/theme';

const Container = styled.div``;

const Button = styled.button`
  transition: all ease-in-out 0.25s;
  font-family: ${(props) => props.theme.fontFamilies.lato};
  &:hover {
    filter: brightness(95%);
    box-shadow: none;
  }
`;

const CustomButton = (props) => {
  const { variant, type, loading, customStyle, children, onClick, loaderStyle, ...rest } = props;

  const getButtonStyle = (variant) => {
    switch (variant) {
      case variants.primary:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: theme.colors.primary.main,
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          cursor: 'pointer',
          fontSize: theme.fontSizes['text-base'],
          border: 'none',
          color: '#fff',
          boxShadow: '0px 4px 8px rgba(117, 131, 142, 0.04), 0px 8px 8px rgba(52, 60, 68, 0.1)',
        };
      case variants.secondary:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: '#f0f0f0',
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          cursor: 'pointer',
          fontSize: theme.fontSizes['text-sm'],
          border: 'none',
          color: '#010101',
          boxShadow: '0px 4px 8px rgba(117, 131, 142, 0.04), 0px 8px 8px rgba(52, 60, 68, 0.1)',
        };
      case variants.disabled:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: '#f0f0f0',
          fontSize: theme.fontSizes['text-sm'],
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          pointerEvents: 'none',
          border: 'none',
          cursor: 'not-allowed',
        };
      default:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: theme.colors.primary.main,
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          cursor: 'pointer',
          fontSize: theme.fontSizes['text-sm'],
          border: 'none',
          color: '#fff',
          boxShadow: '0px 4px 8px rgba(117, 131, 142, 0.04), 0px 8px 8px rgba(52, 60, 68, 0.1)',
        };
    }
  };

  const getSpinnerStyle = (variant) => {
    switch (variant) {
      case variants.primary:
        return {
          color: theme.colors.miscellaneous.white,
          fontSize: '1.25rem',
        };
      case variants.secondary:
        return {
          color: theme.colors.primary.main,
          fontSize: '1.25rem',
        };
      case variants.disabled:
        return {
          color: theme.colors.primary.main,
          fontSize: '1.25rem',
        };
      default:
        return {
          color: theme.colors.miscellaneous.white,
          fontSize: '1.25rem',
        };
    }
  };

  return (
    <Container>
      {loading ? (
        <Button type="button" style={{ ...getButtonStyle(variants.variant), ...customStyle }} {...rest}>
          <i className="fas fa-spinner fa-spin" style={{ ...getSpinnerStyle(variant), ...loaderStyle }} />
        </Button>
      ) : (
        <Button type={type} onClick={onClick} style={{ ...getButtonStyle(variant), ...customStyle }} {...rest}>
          {children}
        </Button>
      )}
    </Container>
  );
};

CustomButton.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  customStyle: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  loaderStyle: PropTypes.object,
};

CustomButton.defaultProps = {
  variant: '',
  type: 'submit',
  loading: false,
  customStyle: {},
  children: '',
  onClick: () => {},
  loaderStyle: {},
};

export default CustomButton;
