import '../styles/errorPage.scss';

const Page404 = () => (
  <div className="container">
    <div className="wrapper">
      <div className="neon">404</div>
      <div className="message1">Page Not Found</div>
      <div className="message2">The page you were looking for doesn't exist</div>
    </div>
  </div>
);

export default Page404;
