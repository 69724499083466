import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { updateCreateBooking, updateAddress } from '../../utils/redux/createBookingSlice';
import CustomInput from '../Adapters/CustomInput';
import apiInstance from '../../utils/apiInstance';
import Vehicles from './Vehicles';

import { handlePrefillUserInCreateBooking } from '../../utils/helpers/component';
import CustomButton from '../Adapters/CustomButton';


const Bottom = styled.div``;

const Heading = styled.h5`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
`;

const Hr = styled.hr`
  margin: 15px 0;
  border: none;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.main}`};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 25px 0;
  gap: 30px;
  @media (max-width: ${(props) => props.theme.screenSizes.tablet}) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 16px 0;
  }
`;

const ProfileInfo = styled.div`
  margin: 15px 0;
`;

const VehicleInfo = styled.div`
  margin: 15px 0;
`;

const SubText = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
`;

const InputContainer = styled.div`
  flex: 1;
`;

const Lable = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
`;

const MobileLable = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  margin-bottom: 10px;
`;

const MobileNumberInputWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.miscellaneous.red};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xs']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xs']};
`;

const UserExitsText = styled.span`
  color: ${(props) => props.theme.colors.miscellaneous.green};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xs']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xs']};
`;

const initailAddressDetails = {
  mobile_number: '',
  business_number: '',
  home_number: '',
  first_name: '',
  last_name: '',
  email: '',
  vehicles: [],
  addresses: [],
};

const UserForm = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    phoneNumber: {
      status: false,
      message: '',
    },
    businessNumber: {
      status: false,
      message: '',
    },
    homeNumber: {
      status: false,
      message: '',
    },
  });
  const [isOpen, setIsOpen] = useState(true);
  const [userExits, setUserExits] = useState(false);
  const [userExitsDetails, setUserExitsDetails] = useState(initailAddressDetails);
  const { createBooking, garage } = useSelector((state) => state);

  const updateFieldData = useCallback((fieldLable, value) => {
    dispatch(updateCreateBooking({ [fieldLable]: value }));
  }, []);



  const handleMobileNumberInput = (e) => {
    setUserExits(false);
    setErrors((state) => ({
      ...state,
      phoneNumber: { status: false, message: '' },
    }));
    setUserExitsDetails(initailAddressDetails);
    dispatch(updateCreateBooking({ phoneNumber: e, selectedVehicleId: '', selectedAddressId: '', userId: '' }));
  };

  const onMobileNumberInputFieldBlur = async () => {
    if (createBooking.value.phoneNumber.length === 0) {
      setErrors((state) => ({
        ...state,
        phoneNumber: { status: false, message: '' },
      }));
    } else if (!isValidPhoneNumber(`+${createBooking.value.phoneNumber}`)) {
      setErrors((state) => ({
        ...state,
        phoneNumber: { status: true, message: 'Invalid Phone Number' },
      }));
    } else {
      try {
        setErrors((state) => ({
          ...state,
          phoneNumber: { status: false, message: '' },
        }));

        const res = await apiInstance.post(`customer/profile`, {
          garageId: garage?.value?.id,
          phoneNumber: `+${createBooking?.value?.phoneNumber}`,
        });
        const userDetails = res.data.content;
        setUserExits(true);
        if (!isEmpty(userDetails)) {
          setUserExitsDetails(userDetails);
          setIsOpen(false);
          handlePrefillUserInCreateBooking({ userDetails, dispatch, updateCreateBooking });
        }
        setIsOpen(true);
        setErrors((state) => ({
          ...state,
          phoneNumber: { status: false, message: '' },
          businessNumber: { status: false, message: '' },
          homeNumber: { status: false, message: '' },
        }));
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const onMobileNumberInputFieldFocus = () => {};


  return (
    <Bottom>
      <ProfileInfo>
        <Heading>Profile</Heading>
        <SubText>Tell us about yourself</SubText>
        <Hr />
        <Flex>
          <MobileNumberInputWrapper>
            <MobileLable>Phone Number</MobileLable>
            <PhoneInput
              country={'ca'}
              enableSearch
              value={createBooking?.value?.phoneNumber}
              onChange={handleMobileNumberInput}
              containerClass={'phoneInput2Container'}
              inputClass={'phoneInput2Input'}
              onBlur={onMobileNumberInputFieldBlur}
              onFocus={onMobileNumberInputFieldFocus}
            />
            {errors.phoneNumber.status ? (
              <ErrorText>{errors.phoneNumber.message}</ErrorText>
            ) : (
              userExits && createBooking?.value?.phoneNumber && <UserExitsText>User Already Exits</UserExitsText>
            )}
          </MobileNumberInputWrapper>
        </Flex>
        <Flex>
          <InputContainer>
            <Lable>First Name *</Lable>
            <CustomInput
              type="text"
              onChange={(e) => {
                updateFieldData('fName', e.target.value);
              }}
              value={createBooking?.value?.fName}
              required
              name="First Name"
              style={{ height: '50px' }}
            />
          </InputContainer>
          <InputContainer>
            <Lable>Last Name *</Lable>
            <CustomInput
              required
              type="text"
              onChange={(e) => {
                updateFieldData('lName', e.target.value);
              }}
              value={createBooking?.value?.lName}
              name="Last Name"
              style={{ height: '50px' }}
            />
          </InputContainer>
        </Flex>
      </ProfileInfo>
      <VehicleInfo>
        <Heading>Vehicle Information</Heading>
        <Hr />
        {!isEmpty(userExitsDetails?.vehicles) && <Vehicles vehicles={userExitsDetails?.vehicles ?? []} />}
          <Flex>
            <InputContainer>
              <Lable>Vehicle Make *</Lable>
              <CustomInput
                required
                type="text"
                onChange={(e) => {
                  updateFieldData('vehicleMake', e.target.value);
                }}
                value={createBooking?.value?.vehicleMake}
                name="Vehicle Make"
                style={{ height: '50px' }}
              />
            </InputContainer>
            <InputContainer>
              <Lable>Vehicle Model *</Lable>
              <CustomInput
                required
                type="text"
                onChange={(e) => {
                  updateFieldData('vehicleModel', e.target.value);
                }}
                value={createBooking?.value?.vehicleModel}
                name="Vehicle Model"
                style={{ height: '50px' }}
              />
            </InputContainer>
          </Flex> 
          <Flex>
            <InputContainer>
              <Lable>Year of Manufacture *</Lable>
              <CustomInput
                required
                type="number"
                className="number-input"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  updateFieldData('vehicleManufactureYear', e.target.value);
                }}
                value={createBooking?.value?.vehicleManufactureYear}
                name="Year of Manufacture"
                style={{ height: '50px' }}
              />
            </InputContainer>
            <InputContainer>
              <Lable>License Plate Number</Lable>
              <CustomInput
                required={false}
                type="text"
                onChange={(e) => {
                  updateFieldData('liscencePlate', e.target.value);
                }}
                value={createBooking?.value?.liscencePlate}
                name="License Plate Number"
                style={{ height: '50px' }}
              />
            </InputContainer>
          </Flex>
      </VehicleInfo>
    </Bottom>
  );
};

export default UserForm;
