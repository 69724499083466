import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomModal from '../components/Adapters/CustomModal';
import CreateAppointmentSuccessModalContent from '../components/CreateAppointmentSuccessModalContent';

const Container = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
`;

const RootLayout = () => {
  const { paymentSuccessModal } = useSelector((state) => state?.modals);

  return (
    <Container>
      <Outlet />
      <CustomModal
        open={paymentSuccessModal}
        handleClose={() => {
          window.location.reload();
        }}
      >
        <CreateAppointmentSuccessModalContent />
      </CustomModal>
    </Container>
  );
};

export default RootLayout;
