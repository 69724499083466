import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { includes, isEmpty } from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { FIREBASE_STORAGE } from '../../config/firebaseConfig';
import { BOOKING_DAYS_COLOR_CLASS_NAME_MAP, ERROR_TOAST_OPTIONS, STREET_NAME_KEYS } from '../constants';
import { capitalize } from './string';
import { QR_LINK } from '../localization';

export const convertArrayToHashMap = (arrayInput = [], mapBy = 'id') => {
  const object = {};
  arrayInput.forEach((item) => {
    object[item[mapBy]] = item;
  });
  return object;
};

export const convertHashMapToArray = (objectInput) => {
  const array = Object.values(objectInput);
  return array;
};

export const disableBodyScroll = () => {
  const body = window.document.getElementsByTagName('body');
  body[0].style.overflowY = 'hidden';
};

export const enableBodyScroll = () => {
  const body = window.document.getElementsByTagName('body');
  body[0].style.overflowY = 'auto';
};

export const errorLogger = (error, addToast, callback = () => {}) => {
  switch (error?.response?.status) {
    case 400:
      addToast(error?.response?.data?.message, ERROR_TOAST_OPTIONS);
      break;
    case 403:
      addToast(error?.response?.data?.message, ERROR_TOAST_OPTIONS);
      break;
    case 404:
      addToast("The route you were looking for doesn't exist", ERROR_TOAST_OPTIONS);
      break;
    case 409:
      addToast(error?.response?.data, ERROR_TOAST_OPTIONS);
      break;
    case 422:
      addToast(error?.response?.data, ERROR_TOAST_OPTIONS);
      break;
    case 500:
      addToast(
        'Server failed, we’re working on fixing the problem. Please try refreshing the page or try again later.',
        ERROR_TOAST_OPTIONS
      );
      break;
    default:
      break;
  }
  callback();
};

export const getCurrentMomentDateInputObject = () => {
  const date = new Date();
  return {
    year: date.getFullYear(),
    month: Number(date.getMonth()) + 1,
    day: date.getDate(),
  };
};

export const contactNumbersValidation = (createBookingData) => {
  if (createBookingData.phoneNumber || createBookingData.businessNumber || createBookingData.homeNumber) {
    return (
      (createBookingData?.phoneNumber ? isValidPhoneNumber(`+${createBookingData.phoneNumber}`) : true) &&
      (createBookingData?.businessNumber ? isValidPhoneNumber(`+${createBookingData.businessNumber}`) : true) &&
      (createBookingData?.homeNumber ? isValidPhoneNumber(`+${createBookingData.homeNumber}`) : true)
    );
  }
  return false;
};

export const contactNumbersUniqueValidation = (createBookingData) => {
  if (createBookingData.phoneNumber || createBookingData.businessNumber || createBookingData.homeNumber) {
    return (
      (createBookingData.phoneNumber.length > 0 && createBookingData.businessNumber.length > 0
        ? createBookingData.phoneNumber !== createBookingData.businessNumber
        : true) &&
      (createBookingData.businessNumber.length > 0 && createBookingData.homeNumber.length > 0
        ? createBookingData.businessNumber !== createBookingData.homeNumber
        : true) &&
      (createBookingData.homeNumber.length > 0 && createBookingData.phoneNumber.length > 0
        ? createBookingData.homeNumber !== createBookingData.phoneNumber
        : true)
    );
  }
  return false;
};

export const createBookingAverageDataMap = ({ bookingAverageData }) =>
  new Promise((resolve, reject) => {
    try {
      const bookingAverageDataMap = bookingAverageData.reduce((accumulator, item) => {
        const date = new Date(item?._id);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        accumulator[`${year}-${month}-${day}`] = item;
        return accumulator;
      }, {});
      resolve(bookingAverageDataMap);
    } catch (error) {
      reject(error);
    }
  });

export const classNamesForCreateAppointmentCalander = async ({
  startDate,
  endDate,
  // bookingAverageData,
  totalNumberVehicles,
  weekClosedDays,
  holidays,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      // const bookingAverageDataMap = await createBookingAverageDataMap({ bookingAverageData });
      const days = [];
      const currentDateObject = moment(new Date(startDate));
      const endDateObject = moment(new Date(endDate));
      while (currentDateObject.isBefore(endDateObject)) {
        const formattedDate = currentDateObject.format('YYYY-MM-DD');
        if (!includes(weekClosedDays, currentDateObject.day()) && !includes(holidays, formattedDate)) {
          // const formattedDate = currentDateObject.format('YYYY-MM-DD');
          const className = BOOKING_DAYS_COLOR_CLASS_NAME_MAP.bookingLessThan50;
          // if (bookingAverageDataMap[formattedDate]) {
          //   const bookingPercentage = (bookingAverageDataMap[formattedDate].count / totalNumberVehicles) * 100;
          //   if (bookingPercentage <= 50) {
          //     className = BOOKING_DAYS_COLOR_CLASS_NAME_MAP.bookingLessThan50;
          //   } else if (bookingPercentage > 50 && bookingPercentage < 100) {
          //     className = BOOKING_DAYS_COLOR_CLASS_NAME_MAP.booking50To100;
          //   } else {
          //     className = BOOKING_DAYS_COLOR_CLASS_NAME_MAP.bookingAbove100;
          //   }
          // } else {
            
          // }
          days.push({
            year: currentDateObject.get('year'),
            month: currentDateObject.get('month') + 1,
            day: currentDateObject.get('date'),
            className,
          });
        }
        currentDateObject.add(1, 'day');
      }
      resolve(days);
    } catch (error) {
      reject(error);
    }
  });

export const getBookingSlotsMap = ({ slotsByDayDayData, slots }) => {
  const slotsByDayDayDataMap = convertArrayToHashMap(slotsByDayDayData, '_id');
  return slots.reduce((accumulator, slot) => {
    if (slotsByDayDayDataMap[slot?._id]?.count >= slot.no_of_vehicles) {
      accumulator[slot?._id] = {
        filled: true,
        totalNumberOfVehicles: slot.no_of_vehicles,
        noOfVehiclesBooked: slotsByDayDayDataMap[slot?._id]?.count ? slotsByDayDayDataMap[slot?._id].count : 0,
      };
      return accumulator;
    }
    accumulator[slot?._id] = {
      filled: false,
      totalNumberOfVehicles: slot.no_of_vehicles,
      noOfVehiclesBooked: slotsByDayDayDataMap[slot?._id]?.count ? slotsByDayDayDataMap[slot?._id].count : 0,
    };
    return accumulator;
  }, {});
};

export const generateDateForBookingManagementArray = (date) => {
  let left = new Date(date).setDate(new Date(date).getDate() - 7);
  const right = new Date(date).setDate(new Date(date).getDate() + 7);
  const dateArray = [];
  while (new Date(left) <= new Date(right)) {
    dateArray.push({
      day: new Date(left).getDate(),
      month: new Date(left).getMonth() + 1,
      year: new Date(left).getFullYear(),
    });
    left = new Date(left).setDate(new Date(left).getDate() + 1);
  }
  return dateArray;
};

export const convertFirstLetterToCaps = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const uploadMediaToFireBase = async (file) => {
  const storageRef = ref(FIREBASE_STORAGE, uuidv4());
  const snapshot = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(ref(FIREBASE_STORAGE, snapshot.ref.fullPath));
  return url;
};

export const filterBoookingByStatus = (bookings, status) => bookings.filter((booking) => booking.status === status);

export const setInputValueById = (id, value) => {
  const input = document.getElementById(id);
  Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(input, value);
  input.dispatchEvent(new Event('change', { bubbles: true }));
};

export const getFormattedAddress = (address) => {
  const addressArray = [];
  ['street', 'city', 'province', 'postalCode'].forEach((key) => {
    if (address[key]) {
      addressArray.push(address[key]);
    }
  });
  return addressArray.join(', ');
};

export const formatCreateAppointmentBodyData = (createBookingData, slots, garageId, images) => {
  let subTotal = createBookingData?.services.reduce(
    (accumulator, currentValue) => accumulator + currentValue.servicePrice * currentValue?.quantity,
    0
  );
  if (createBookingData.includeDiagnostics) {
    subTotal += createBookingData?.diagnosticsPrice;
  }
  const discount = createBookingData?.promoCodeDiscountPrice;
  const discountedSubtotal = subTotal - discount;
  const tax = discountedSubtotal * (createBookingData.taxPercentage / 100);
  const total = discountedSubtotal + tax;

  const services = createBookingData?.services.map((service) => ({
    service_price: Number(service.service_price) || 0,
    title: service.title,
    service_id: service.id || service.serviceId,
    quantity: Number(service.quantity) || 0,
    labour_price: Number(service.labour_price) || 0,
    description: service.description || "",
    duration: service.duration || 0,
    // type: service.type,
  }));

  const formattedAddress = createBookingData?.address?.formattedAddress
    ? createBookingData?.address?.formattedAddress
    : getFormattedAddress(createBookingData?.address);

  const address = {
    street: capitalize(createBookingData?.address?.street),
    city: capitalize(createBookingData?.address?.city),
    province: capitalize(createBookingData?.address?.province),
    postal_code: createBookingData?.address?.postalCode,
    formatted_address: formattedAddress,
    place_id: createBookingData?.address?.placeId,
    lat: createBookingData?.address?.lat,
    lng: createBookingData?.address?.lng,
    address_id: createBookingData.selectedAddressId,
  };

  const vehicle = {
    kms_driven: createBookingData?.vehicleDistanceDriven,
    name: capitalize(
      `${createBookingData?.vehicleMake} ${createBookingData?.vehicleModel} ${createBookingData?.vehicleManufactureYear}`
    ),
    make: capitalize(createBookingData?.vehicleMake),
    model: capitalize(createBookingData?.vehicleModel),
    year: createBookingData?.vehicleManufactureYear,
    vehicle_id: createBookingData.selectedVehicleId,
    // vin: createBookingData?.vehicleVIN,
    // licence_plate: createBookingData?.liscencePlate,
    ...(createBookingData?.vin && { vin: createBookingData.vin }),
    ...(createBookingData?.liscencePlate && { licence_plate: createBookingData.liscencePlate }),
  };

  const userDetails = {
    mobile_number: `+${createBookingData?.phoneNumber}`,
    business_number: createBookingData?.businessNumber ? `+${createBookingData?.businessNumber}` : '',
    home_number: createBookingData?.homeNumber ? `+${createBookingData?.homeNumber}` : '',
    first_name: capitalize(createBookingData?.fName),
    last_name: capitalize(createBookingData?.lName),
    email: createBookingData?.email,
    user_id: createBookingData?.userId,
  };

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedSlot = slots[createBookingData?.slotId];
  const time = selectedSlot?.formatted_24h?.split(' - ');
  const droffoffDate = moment(
    `${createBookingData?.dropoffDate?.year}-${createBookingData?.dropoffDate?.month}-${createBookingData?.dropoffDate?.day} ${time[0]}:00`,
    'YYYY-M-D HH:mm:ss'
  );

  const dropoffTime = {
    ...selectedSlot,
    value: selectedSlot?.formatted_am_pm,
  };

  const body = {
    garage_id: garageId,
    services,
    formatted_date: `${createBookingData.dropoffDate.year}${createBookingData.dropoffDate.month}${createBookingData.dropoffDate.day}`,
    dropoff_date: droffoffDate.toISOString(),
    dropoff_time: dropoffTime,
    user: userDetails,
    vehicle_id: vehicle,
    timezone: tz,
    slot_id: createBookingData?.slotId,
    remarks: createBookingData?.remarks,
  };

  return { body, subTotal, tax, total };
};

export const formatStreetName = (address) => {
  const streetArray = [];
  STREET_NAME_KEYS.forEach((item) => {
    if (address[item]) {
      streetArray.push(address[item]);
    }
  });
  return streetArray.join(', ');
};

export const getSelectedVehicleId = (userDetails) => {
  if (userDetails?.current_vehicle_id) {
    if (Array.isArray(userDetails?.vehicles) && !isEmpty(userDetails?.vehicles)) {
      const vehicle = userDetails?.vehicles?.find((vehicle) => vehicle?.id === userDetails?.current_vehicle_id);
      if (vehicle) {
        return userDetails?.current_vehicle_id;
      }
      return userDetails?.vehicles[userDetails?.vehicles.length - 1]?.id;
    }
    return '';
  }
  if (Array.isArray(userDetails?.vehicles) && !isEmpty(userDetails?.vehicles)) {
    return userDetails?.vehicles[userDetails?.vehicles.length - 1]?.id;
  }
  return '';
};

export const getSelectedAddressId = (userDetails) => {
  if (userDetails?.current_address_id) {
    if (Array.isArray(userDetails?.addresses) && !isEmpty(userDetails?.addresses)) {
      const address = userDetails?.addresses?.find((address) => address?._id === userDetails?.current_address_id);
      if (address) {
        return userDetails?.current_address_id;
      }
      return userDetails?.addresses[userDetails?.addresses.length - 1]?._id;
    }
    return '';
  }
  if (Array.isArray(userDetails?.addresses) && !isEmpty(userDetails?.addresses)) {
    return userDetails?.addresses[userDetails?.addresses.length - 1]?._id;
  }
  return '';
};

export const getSelectedVehicle = (vehicles, selectedVehicleId) => {
  const initialVehicleModel = {
    make: '',
    model: '',
    vin: '',
    licence_plate: '',
    year: '',
    kms_driven: '',
  };
  if (Array.isArray(vehicles) && !isEmpty(vehicles)) {
    const vehicle = vehicles?.find((vehicle) => vehicle?.id === selectedVehicleId);
    if (vehicle) {
      return vehicle;
    }
    return initialVehicleModel;
  }
  return initialVehicleModel;
};

export const getSelectedAddress = (addresses, selectedAddressId) => {
  const initialAddressModel = {
    street: '',
    city: '',
    province: '',
    postal_code: '',
    formatted_address: '',
    place_id: '',
    lat: '',
    lng: '',
  };
  if (Array.isArray(addresses) && !isEmpty(addresses)) {
    const address = addresses?.find((address) => address?._id === selectedAddressId);
    if (address) {
      return address;
    }
    return initialAddressModel;
  }
  return initialAddressModel;
};

export const handlePrefillUserInCreateBooking = ({ userDetails, dispatch, updateCreateBooking }) => {
  const selectedVehicleId = getSelectedVehicleId(userDetails);
  // const selectedAddressId = getSelectedAddressId(userDetails);
  const vehicle = getSelectedVehicle(userDetails?.vehicles, selectedVehicleId);
  // const address = getSelectedAddress(userDetails?.addresses, selectedAddressId);
  dispatch(
    updateCreateBooking({
      email: userDetails?.email ? userDetails.email : '',
      fName: userDetails?.first_name ? userDetails?.first_name : '',
      lName: userDetails?.last_name ? userDetails?.last_name : '',
      phoneNumber: userDetails?.phone_number ? userDetails?.phone_number.slice(1) : '',
      // vehicleId: selectedVehicleId,
      // vehicleMake: vehicle?.make,
      // vehicleModel: vehicle?.model,
      // vehicleVIN: vehicle?.vin,
      // liscencePlate: vehicle?.licence_plate,
      // vehicleManufactureYear: vehicle?.year,
      userId: userDetails?.id,
    })
  );
};

export const axiosErrorCallback = ({ navigate, error, addToast, errorLoggerCallback }) => {
  if (axios.isAxiosError(error)) {
    switch (error?.response?.status) {
      case 404:
        return navigate('/404');
      case 403:
        return navigate('/403');
      case 422:
        return navigate('/500');
      case 500:
        return navigate('/500');
      default:
        break;
    }
  }
  return errorLogger(error, addToast, errorLoggerCallback);
};

export const formatDataForApplyPromoCodeCreateBooking = (createBookingData, promoCode, garageId) => {
  const services = createBookingData?.services.map((service) => ({
    service_price: Number(service.servicePrice),
    service: service?.serviceTitle || '',
    service_id: service?.serviceId || '',
    quantity: Number(service?.quantity),
    type: service?.type,
  }));

  const parts = [];
  const labourPrices = [];

  if (createBookingData.includeDiagnostics) {
    services.push({
      service_price: Number(createBookingData?.diagnosticsPrice),
      service: 'Diagnostics',
      quantity: 1,
      type: 'diagnostics',
    });
  }

  return {
    code: promoCode,
    date: new Date().toISOString(),
    user_id: createBookingData?.userId ? createBookingData?.userId : null,
    garage_id: garageId,
    cart: {
      services,
      parts,
      labourPrices,
    },
  };
};
