export const EMAIL = 'email';

export const OTP = 'otp';

export const REDUX_PERSIST = 'redux_persist';

export const NUMBER = 'number';

export const CURRENCY = 'currency';

export const DUE = 'Due';

export const PAID = 'Paid';

export const BOOKINGS_ALL = 'bookingsAll';

export const BOOKINGS_CHECKED_IN = 'bookingsCheckedIn';

export const BOOKINGS_IN_SERVICE = 'bookingsInService';

export const BOOKINGS_PICKUP = 'bookingsPickup';

export const BOOKINGS_COMPLETED = 'bookingsCompleted';

export const DRAGGED_BOOKING_SOURCE = 'draggedBookingSource';

export const ONLINE = 'online';

export const OFFINE = 'cash';

export const TEXT_MESSAGE_TYPE = 0;

export const IMAGE_FILE_MESSAGE_TYPE = 1;

export const VIDEO_FILE_MESSAGE_TYPE = 2;

export const APPLICATION_FILE_MESSAGE_TYPE = 3;

export const IMAGE_FILE_TYPE = 'image';

export const VIDEO_FILE_TYPE = 'video';

export const APPLICATION_FILE_TYPE = 'application';

export const VEHICLE_IN_SERVICE = 'vehiclesInService';

export const VEHICLE_CHECKED_IN = 'vehiclesCheckedIn';

export const APPOINTMENTS_BOOKED = 'appointmentsBooked';

export const TOTAL_REVENUE = 'totalRevenue';

export const REGULAR = 'regular';

export const SEASONAL = 'seasonal';

export const CUSTOM = 'custom';

export const TYPE = 'type';

export const CATEGORY = 'category';

export const _ID = '_id';

export const ADD = 'add';

export const UPDATE = 'update';

export const SUBMIT = 'submit';

export const BUTTON = 'button';

export const GMS = 'gms';

export const APPOINTMENT_STATUS = 1;

export const BOOKING_CHECKED_IN_STATUS = 2;

export const BOOKING_IN_SERVICE_STATUS = 3;

export const BOOKING_PICKUP_STATUS = 4;

export const BOOKING_COMPLETED_STATUS = 5;

export const BOOKING_CANCELED_STATUS = 6;

export const GARAGE_SERVICE_TYPE = 1;

export const CUSTOM_SERVICE_TYPE = 2;

export const WEB = 'web';

export const QR_LINK = 'qrlink';

export const ERROR = 'error';

export const SUCCESS = 'success';

export const WARNING = 'warning';
