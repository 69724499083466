import { Box, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { stepTheme } from '../../utils/mui/createThemes';
import { BOOKING_CHECKED_IN_STATUS } from '../../utils/localization';
import ServiceItems from './ServiceItems';
import PartsCostContent from './PartsCostContent';
import LabourCostContent from './LabourCostContent';
import DiagnosticsContent from './DiagnosticsContent';
import PaymentSummary from './PaymentSummary';

const Container = styled.div`
  padding-bottom: 30px;
`;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
`;

const Date = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
`;

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const BoxHeading = styled.h4`
  margin: 0 0 10px 0;
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
`;

const RemarkContent = styled.p`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.lineHeights['text-base']};
`;

const steps = [
  {
    label: 'Check In',
    value: BOOKING_CHECKED_IN_STATUS,
  },
  {
    label: 'Servicing',
  },
  {
    label: 'Waiting For Pick Up',
  },
  {
    label: 'Completed',
  },
];

// eslint-disable-next-line arrow-body-style
const StautsTrackingContent = (props) => {
  const { bookingDetails } = props;

  const checkinTime = moment(bookingDetails?.checked_in_at);
  const servicinTime = moment(bookingDetails?.service_started_at);
  const vehiclePickedTime = moment(bookingDetails?.vehicle_picked_at);
  const serviceCompletedTime = moment(bookingDetails?.service_completed_at);

  const checkedInAt = `${checkinTime.format(`hh:mm A`)} | ${checkinTime.format(`D MMM`)}`;
  const serviceStartedAt = `${servicinTime.format(`hh:mm A`)} | ${servicinTime.format(`D MMM`)}`;
  const vehiclePickedAt = `${vehiclePickedTime.format(`hh:mm A`)} | ${vehiclePickedTime.format(`D MMM`)}`;
  const serviceCompletedAt = `${serviceCompletedTime.format(`hh:mm A`)} | ${serviceCompletedTime.format(`D MMM`)}`;

  const getStatus = (status) => {
    switch (status) {
      case 2:
        return 1;
      case 3:
        return 2;
      case 4:
        return 3;
      case 5:
        return 4;
      default:
        break;
    }
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return <Heading>Ready to Check In</Heading>;
      case 2:
        return (
          <>
            <Heading>Checked In</Heading>
            <Date>{checkedInAt}</Date>
          </>
        );
      case 3:
        return (
          <>
            <Heading>Servicing</Heading>
            <Date>{serviceStartedAt}</Date>
          </>
        );
      case 4:
        return (
          <>
            <Heading>Waiting for Pickup</Heading>
            <Date>{vehiclePickedAt}</Date>
          </>
        );
      case 5:
        return (
          <>
            <Heading>Completed</Heading>
            <Date>{serviceCompletedAt}</Date>
          </>
        );
      default:
        break;
    }
  };

  return (
    <Container>
      <ThemeProvider theme={stepTheme}>
        <Stepper activeStep={getStatus(bookingDetails?.status)}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
        <Wrapper>
          {getStatusName(bookingDetails?.status)}
          <ServiceItems bookingDetails={bookingDetails} />
          <PartsCostContent bookingDetails={bookingDetails} />
          <LabourCostContent bookingDetails={bookingDetails} />
          <DiagnosticsContent bookingDetails={bookingDetails} />
          {bookingDetails?.remarks && (
            <Box sx={{ mt: 3 }}>
              <BoxHeading>Remarks</BoxHeading>
              <Box sx={{ border: 1, p: 3, borderRadius: '10px' }}>
                <RemarkContent>{bookingDetails?.remarks}</RemarkContent>
              </Box>
            </Box>
          )}
          <PaymentSummary bookingDetails={bookingDetails} />
        </Wrapper>
      </ThemeProvider>
    </Container>
  );
};

StautsTrackingContent.propTypes = {
  bookingDetails: PropTypes.object,
};

export default StautsTrackingContent;
